<template>
    <div class="row mt-2">
        <div class="col-md-6 offset-md-3 col-xl-4 offset-xl-4">
            <form>
                <div class="form-group">
                    <label>Login</label>
                    <input v-model="login" type="text" class="form-control" placeholder="Login" required>
                </div>
                <div class="form-group">
                    <label>Password</label>
                    <input v-model="password" type="password" class="form-control" placeholder="Password" required>
                </div>
                <transition enter-active-class="animated flipInX"
                            leave-active-class="animated flipOutX">
                    <div v-if="invalidCredentials" class="form-group">
                        <small class="text-danger">Неверный логин и/или пароль.</small>
                    </div>
                </transition>
                <button type="submit" @click.prevent="onSubmit" class="btn btn-primary">Login</button>
            </form>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Index",
        data() {
            return {
                login: '',
                password: '',
                invalidCredentials: false
            }
        },
        methods: {
            check_token() {
                let token = this.$store.state.auth.token;
                if (token !== '') {
                    this.$store.commit('prop/loading_spinner_show');
                    this.$axios.post('/protected')
                        .then((res) => {
                            if (res.data.status == 'success') {
                                let role = this.$store.state.auth.role;
                                this.goToIndexPage(role);
                            } else if (res.data.status == 'not_authorized') {
                                this.$store.commit('auth/logout');
                            }
                            this.$store.commit('prop/loading_spinner_hide');
                        })
                        .catch(() => {
                            // console.log(error);
                            this.$store.commit('prop/loading_spinner_hide');
                        });
                } else {
                    this.$store.commit('auth/logout');
                }
            },
            onSubmit() {
                this.$store.commit('prop/loading_spinner_show');
                this.$axios.post('/login', {'login': this.login, 'password': this.password})
                    .then((res) => {
                        if (res.data.status == 'success') {
                            let official_position = res.data.official_position;
                            let city = res.data.city;
                            let balance = res.data.balance;
                            let fio = res.data.fio;
                            let role = res.data.role;
                            let token = res.data.token;
                            this.$store.commit('auth/auth_success', {
                                login: this.login,
                                role: role,
                                token: token,
                                fio: fio,
                                balance: balance,
                                city: city,
                                official_position: official_position,
                            });
                            this.goToIndexPage(role);
                        } else if (res.data.status == 'bad_data') {
                            this.invalidCredentials = true;
                        }
                        this.$store.commit('prop/loading_spinner_hide');
                    })
                    .catch(() => {
                        // console.log(error);
                        this.$store.commit('prop/loading_spinner_hide');
                    });
            },
            goToIndexPage(role) {
                if (role === 'admin') {
                    this.$router.push('/admin/corrections/1');
                } else if (role === 'user') {
                    this.$router.push('/user/prizes');
                } else if (role === 'manager') {
                    this.$router.push('/manager/specifications/1');
                } else {
                    console.log('ppc');
                }
            }

        },
        created() {
            this.check_token();
        }
    }
</script>

<style scoped>

</style>