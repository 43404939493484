<template>
    <div class="m-3">
        <h3>Новости</h3>
        <b-card-group deck>
            <b-card no-body v-for="item in items" v-bind:key="item.id"
                    class="overflow-hidden m-2"
                    style="max-width: 400px; min-width: 350px;">
                <b-card-img :src="item.file" alt="Image"
                            class="rounded-1"></b-card-img>
                <b-card-body :title="item.name">
                    <b-card-text>
                        {{item.description}}
                    </b-card-text>
                    <a :href="item.url" class="float-right p-2" target="_blank">подробней</a>
                </b-card-body>
            </b-card>
        </b-card-group>
    </div>
</template>

<script>
    export default {
        name: "News",
        data() {
            return {
                items: []
            }
        },
        methods: {
            loadData() {
                this.$store.commit('prop/loading_spinner_show');
                this.$axios.post('/user/news_data_for_page')
                    .then((res) => {
                        this.items = res.data.items;
                        this.$store.commit('prop/loading_spinner_hide');
                    })
                    .catch((error) => {
                        // eslint-disable-next-line
                        console.log(error);
                        this.$store.commit('prop/loading_spinner_hide');
                    });
            },
        },
        created() {
            this.loadData();
        }
    }
</script>

<style scoped>

</style>