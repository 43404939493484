<template>
    <div class="m-3">
        <div class="row m-2 d-flex justify-content-start">
            <div class="col-6 d-inline-flex">
                <h3>Регионы</h3>
            </div>
            <div class="col-6 text-right justify-content-end">
                <b-button variant="outline-secondary" @click="showModalAddRegion" class="m-2">Добавить регион
                </b-button>
                <b-button variant="outline-secondary" @click="showModalAddCity" class="m-2">Добавить город
                </b-button>
            </div>
        </div>

        <b-list-group>
            <b-list-group-item v-for="rc in region_cities_list" :key="rc.region.id">
                <span style="cursor: pointer; text-decoration: underline;"
                      @click="rc.region.show = !rc.region.show">
                    {{ rc.region.id + ' - ' + rc.region.name }}

                </span>
                <b-icon class="ml-2" style="cursor: pointer;" icon="gear" variant="primary"
                        @click="showModalRegion(rc.region)">
                </b-icon>
                <transition
                        enter-active-class="animated bounceInLeft"
                        leave-active-class="animated bounceOutRight"
                >
                    <div v-show="rc.region.show">
                        <b-table striped hover :items="rc.cities" :fields="fields_table_cities" primary-key="id"
                                 :tbody-transition-props="{name:'flip-list'}">
                            <template #cell(name)="data">
                                {{ data.value }}
                                <b-icon style="cursor: pointer;" icon="gear" variant="primary"
                                        @click="showModalCity(data.item)">
                                </b-icon>
                            </template>
                        </b-table>
                    </div>
                </transition>
            </b-list-group-item>
        </b-list-group>


        <b-modal :title="'Редактировать город - ' + modal_city.id" ref="modalCity"
                 no-close-on-backdrop no-close-on-esc hide-header-close hide-footer>
            <b-form @submit.prevent="submitCityEdit">
                <div class="row">
                    <div class="col">
                        <b-form-group label="Название">
                            <b-form-input type="text"
                                          v-model="modal_city.name"
                                          maxlength="255"
                                          placeholder="Название"
                                          required>
                            </b-form-input>
                        </b-form-group>
                    </div>
                </div>

                <transition enter-active-class="animated flipInX"
                            leave-active-class="animated flipOutX">
                    <div v-show="message.show" class="mb-2">
                            <span :class="message.classText">
                            {{message.text}}
                            </span>
                    </div>
                </transition>
                <div class="modal-footer m-2">
                    <b-button type="submit" variant="primary">Изменить</b-button>
                    <b-button variant="secondary" @click="hideModalCity">Закрыть</b-button>
                </div>
            </b-form>
        </b-modal>

        <b-modal title="Добавить город" ref="modalCityAdd" size="xl"
                 no-close-on-backdrop no-close-on-esc hide-header-close hide-footer>
            <b-form @submit.prevent="submitCityAdd">
                <div class="row">
                    <div class="col-6">
                        <b-form-group
                                :label="modal_city.region_id != null ? 'Регион: ' + modal_city.region_id: 'Выберите регион'"
                                label-for="form-title-input">
                            <b-form-select v-model="modal_city.region_id"
                                           :options="region_cities_list"
                                           class="mb-3"
                                           value-field="region.id"
                                           text-field="region.name"
                                           required>
                            </b-form-select>
                        </b-form-group>
                    </div>
                    <div class="col-6">
                        <b-form-group label="Название">
                            <b-form-input type="text"
                                          v-model="modal_city.name"
                                          maxlength="255"
                                          placeholder="Название"
                                          required>
                            </b-form-input>
                        </b-form-group>
                    </div>
                </div>

                <transition enter-active-class="animated flipInX"
                            leave-active-class="animated flipOutX">
                    <div v-show="message.show" class="mb-2">
                            <span :class="message.classText">
                            {{message.text}}
                            </span>
                    </div>
                </transition>
                <div class="modal-footer m-2">
                    <b-button type="submit" variant="primary">Добавить</b-button>
                    <b-button variant="secondary" @click="hideModalCityAdd">Закрыть</b-button>
                </div>
            </b-form>
        </b-modal>

        <b-modal :title="modal_region.id != null ?'Редактировать регион - ' + modal_region.id : 'Добавить регион'"
                 ref="modalRegion"
                 no-close-on-backdrop no-close-on-esc hide-header-close hide-footer>
            <b-form @submit.prevent="submitRegion">
                <div class="row">
                    <div class="col">
                        <b-form-group label="Название">
                            <b-form-input type="text"
                                          v-model="modal_region.name"
                                          maxlength="255"
                                          placeholder="Название"
                                          required>
                            </b-form-input>
                        </b-form-group>
                    </div>
                </div>

                <transition enter-active-class="animated flipInX"
                            leave-active-class="animated flipOutX">
                    <div v-show="message.show" class="mb-2">
                            <span :class="message.classText">
                            {{message.text}}
                            </span>
                    </div>
                </transition>
                <div class="modal-footer m-2">
                    <b-button type="submit" variant="primary">{{modal_region.id != null ?'Изменить': 'Добавить'}}
                    </b-button>
                    <b-button variant="secondary" @click="hideModalRegion">Закрыть</b-button>
                </div>
            </b-form>
        </b-modal>
    </div>
</template>

<script>
    export default {
        name: "ARegions",
        data() {
            return {
                region_cities_list: [],
                fields_table_cities: [
                    {
                        label: "id",
                        key: 'id',
                        sortable: true
                    },
                    {
                        label: "Название",
                        key: 'name',
                        sortable: true
                    }
                ],
                message: {
                    text: null,
                    show: false,
                    classText: null
                },
                modal_region: {
                    id: null,
                    name: null,
                },
                modal_city: {
                    id: null,
                    name: null,
                    region_id: null
                },
            }
        },
        methods: {
            loadData() {
                this.$store.commit('prop/loading_spinner_show');
                this.$axios.post('/admin/regions/data_for_page')
                    .then((res) => {
                        this.region_cities_list = res.data.region_cities_list;
                        this.$store.commit('prop/loading_spinner_hide');
                    })
                    .catch((error) => {
                        // eslint-disable-next-line
                        console.log(error);
                        this.$store.commit('prop/loading_spinner_hide');
                    });
            },
            showModalAddRegion() {
                this.modal_region.id = null;
                this.modal_region.name = null;
                this.message.show = false;
                this.$refs['modalRegion'].show();
            },
            showModalAddCity() {
                this.modal_city.id = null;
                this.modal_city.name = null;
                this.modal_city.region_id = null;
                this.message.show = false;
                this.$refs['modalCityAdd'].show();
            },
            hideModalCityAdd() {
                this.$refs['modalCityAdd'].hide();
            },
            hideModalCity() {
                this.$refs['modalCity'].hide();
            },
            hideModalRegion() {
                this.$refs['modalRegion'].hide();
            },
            showModalRegion(region) {
                this.modal_region.id = region.id;
                this.modal_region.name = region.name;
                this.message.show = false;
                this.$refs['modalRegion'].show();
            },
            showModalCity(city) {
                this.modal_city.id = city.id;
                this.modal_city.name = city.name;
                this.message.show = false;
                this.$refs['modalCity'].show();
            },
            showModalMessage(text, classText) {
                this.message = {
                    text: text,
                    classText: classText,
                    show: true
                }
            },
            submitRegion() {
                if (this.modal_region.id == null) {
                    this.addRegion();
                } else {
                    this.editRegion();
                }
            },
            addRegion() {
                this.message.show = false;
                this.$store.commit('prop/loading_spinner_show');
                this.$axios.post('/admin/regions/add_region', {
                    region_name: this.modal_region.name
                })
                    .then((res) => {
                        if (res.data.status == 'success') {
                            this.modal_region.name = null;
                            this.region_cities_list = res.data.region_cities_list;
                            this.showModalMessage(res.data.message, 'text-success');
                        } else if (res.data.status == 'bad_data') {
                            this.showModalMessage(res.data.message, 'text-danger');
                        }
                        this.$store.commit('prop/loading_spinner_hide');
                    })
                    .catch((error) => {
                        // eslint-disable-next-line
                        console.log(error);
                        this.$store.commit('prop/loading_spinner_hide');
                    });
            },
            editRegion() {
                this.message.show = false;
                this.$store.commit('prop/loading_spinner_show');
                this.$axios.post('/admin/regions/edit_region', {
                    region_name: this.modal_region.name,
                    region_id: this.modal_region.id,
                })
                    .then((res) => {
                        if (res.data.status == 'success') {
                            this.region_cities_list = res.data.region_cities_list;
                            this.showModalMessage(res.data.message, 'text-success');
                        } else if (res.data.status == 'bad_data') {
                            this.showModalMessage(res.data.message, 'text-danger');
                        }
                        this.$store.commit('prop/loading_spinner_hide');
                    })
                    .catch((error) => {
                        // eslint-disable-next-line
                        console.log(error);
                        this.$store.commit('prop/loading_spinner_hide');
                    });
            },
            submitCityEdit() {
                this.message.show = false;
                this.$store.commit('prop/loading_spinner_show');
                this.$axios.post('/admin/regions/edit_city', {
                    city_name: this.modal_city.name,
                    city_id: this.modal_city.id,
                })
                    .then((res) => {
                        if (res.data.status == 'success') {
                            this.region_cities_list = res.data.region_cities_list;
                            this.showModalMessage(res.data.message, 'text-success');
                        } else if (res.data.status == 'bad_data') {
                            this.showModalMessage(res.data.message, 'text-danger');
                        }
                        this.$store.commit('prop/loading_spinner_hide');
                    })
                    .catch((error) => {
                        // eslint-disable-next-line
                        console.log(error);
                        this.$store.commit('prop/loading_spinner_hide');
                    });
            },
            submitCityAdd() {
                this.message.show = false;
                this.$store.commit('prop/loading_spinner_show');
                this.$axios.post('/admin/regions/add_city', {
                    city_name: this.modal_city.name,
                    region_id: this.modal_city.region_id,
                })
                    .then((res) => {
                        if (res.data.status == 'success') {
                            this.modal_city.name = null;
                            this.modal_city.region_id = null;
                            this.region_cities_list = res.data.region_cities_list;
                            this.showModalMessage(res.data.message, 'text-success');
                        } else if (res.data.status == 'bad_data') {
                            this.showModalMessage(res.data.message, 'text-danger');
                        }
                        this.$store.commit('prop/loading_spinner_hide');
                    })
                    .catch((error) => {
                        // eslint-disable-next-line
                        console.log(error);
                        this.$store.commit('prop/loading_spinner_hide');
                    });
            },
        },
        created() {
            this.loadData();
        }
    }
</script>

<style scoped>

</style>