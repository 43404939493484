<template>
    <div class="m-3">
        <div class="row m-2 d-flex justify-content-start">
            <div class="col-9 d-inline-flex">
                <h3>Новости</h3>
            </div>
            <div class="col-3 text-right d-inline-flex justify-content-end">
                <b-button variant="outline-secondary" @click="showAddModal">Добавить новость</b-button>
            </div>
        </div>

        <b-table striped :items="items" :fields="fields" primary-key="id"
                 head-variant="dark"
                 table-variant="light"
                 :tbody-transition-props="{name:'flip-list'}"
        >
            <template #cell(id)="{item}">
                {{ item.id }}
                <b-icon icon="window" variant="primary" @click="showEditModal(item)" style="cursor: pointer;">
                </b-icon>
            </template>
            <template #cell(news_dt)="data">
                {{ $moment(data.value).format('ll')}}
            </template>
        </b-table>

        <div class="row mt-3 ml-3">
            <div class="col">
                <b-pagination-nav
                        :link-gen="linkGen"
                        :number-of-pages="number_of_pages"
                        use-router
                        align="right">
                </b-pagination-nav>
            </div>
        </div>

        <b-modal :title="modal_item.id != null ?'Редактировать новость - ' + modal_item.id : 'Добавить новость'"
                 ref="modalItem" size="xl"
                 no-close-on-backdrop no-close-on-esc hide-header-close hide-footer>
            <b-form @submit.prevent="submitModalForm">
                <div class="row">
                    <div class="col-6">
                        <b-form-group label="Название">
                            <b-form-input type="text"
                                          v-model="modal_item.name"
                                          maxlength="255"
                                          placeholder="Название"
                                          required>
                            </b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-6">
                        <b-form-group label="Дата"
                                      label-for="form-title-input">
                            <b-form-datepicker v-model="modal_item.news_dt" locale="ru" required
                                               start-weekday="1"></b-form-datepicker>
                        </b-form-group>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <b-form-group label="Описание">
                            <b-form-textarea type="text"
                                             v-model="modal_item.description"
                                             rows="3"
                                             max-rows="6"
                                             maxlength="255"
                                             placeholder="Описание"
                                             required>
                            </b-form-textarea>
                        </b-form-group>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <b-form-group label="Ссылка на новость">
                            <b-form-input type="text"
                                          v-model="modal_item.url"
                                          maxlength="255"
                                          placeholder="Ссылка на новость"
                                          required>
                            </b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-6">
                        <b-form-group label="Ссылка на картинку">
                            <b-form-input type="text"
                                          v-model="modal_item.file"
                                          maxlength="255"
                                          placeholder="Ссылка на картинку"
                                          required>
                            </b-form-input>
                        </b-form-group>
                    </div>
                </div>

                <transition enter-active-class="animated flipInX"
                            leave-active-class="animated flipOutX">
                    <div v-show="message.show" class="mb-2">
                            <span :class="message.classText">
                            {{message.text}}
                            </span>
                    </div>
                </transition>
                <div class="modal-footer m-2">
                    <b-button type="submit" variant="primary">{{ modal_item.id != null ?'Редактировать' : 'Добавить'}}
                    </b-button>
                    <b-button variant="secondary" @click="hideModal">Закрыть</b-button>
                </div>
            </b-form>
        </b-modal>
    </div>
</template>

<script>
    export default {
        name: "ANews",
        data() {
            return {
                items: [],
                page: null,
                per_page: 20,
                number_of_pages: 1,
                fields: [
                    {
                        label: "№",
                        key: 'id',
                        sortable: true
                    },
                    {
                        label: "Название",
                        key: 'name',
                        sortable: true
                    },
                    {
                        label: "Дата",
                        key: 'news_dt',
                        sortable: true
                    },
                    {
                        label: "Ссылка на новость",
                        key: 'url',
                        sortable: true
                    },
                ],
                modal_item: {
                    id: null,
                    name: null,
                    description: null,
                    news_dt: null,
                    url: null,
                    file: null,
                },
                message: {
                    text: null,
                    show: false,
                    classText: null
                },
            }
        },
        methods: {
            loadData() {
                this.$store.commit('prop/loading_spinner_show');
                this.page = this.$route.params.page;
                this.$axios.post('/admin/news_data_for_page', {
                    page: this.page,
                    per_page: this.per_page,
                })
                    .then((res) => {
                        this.items = res.data.items;
                        this.number_of_pages = res.data.number_of_pages;
                        this.$store.commit('prop/loading_spinner_hide');
                    })
                    .catch((error) => {
                        // eslint-disable-next-line
                        console.log(error);
                        this.$store.commit('prop/loading_spinner_hide');
                    });
            },
            showModalMessage(text, classText) {
                this.message = {
                    text: text,
                    classText: classText,
                    show: true
                }
            },
            showAddModal() {
                this.message.show = false;
                this.modal_item = {
                    id: null,
                    name: null,
                    description: null,
                    news_dt: null,
                    url: null,
                    file: null,
                };
                this.$refs['modalItem'].show();
            },
            showEditModal(item) {
                this.message.show = false;
                this.modal_item = JSON.parse(JSON.stringify(item));
                this.$refs['modalItem'].show();
            },
            hideModal() {
                this.$refs['modalItem'].hide();
            },
            linkGen(pageNum) {
                return `/admin/guide/news/${pageNum}`;
            },
            submitModalForm() {
                if (this.modal_item.id == null) {
                    this.addNews();
                } else {
                    this.editNews();
                }
            },
            addNews() {
                if (this.modal_item.news_dt === null) {
                    this.showModalMessage('Укажите дату', 'text-danger')
                    return null
                }

                this.message.show = false;
                this.$store.commit('prop/loading_spinner_show');
                let post_data = this.modal_item;
                post_data.page = this.page;
                post_data.per_page = this.per_page;
                this.$axios.post('/admin/news_add_item', post_data)
                    .then((res) => {
                        if (res.data.status == 'success') {
                            this.items = res.data.items;
                            this.number_of_pages = res.data.number_of_pages;
                            this.modal_item = {
                                id: null,
                                name: null,
                                description: null,
                                news_dt: null,
                                url: null,
                                file: null,
                            };
                            this.showModalMessage(res.data.message, 'text-success');
                        } else if (res.data.status == 'bad_data') {
                            this.showModalMessage(res.data.message, 'text-danger');
                        }
                        this.$store.commit('prop/loading_spinner_hide');
                    })
                    .catch((error) => {
                        // eslint-disable-next-line
                        console.log(error);
                        this.$store.commit('prop/loading_spinner_hide');
                    });
            },
            editNews() {
                this.message.show = false;
                this.$store.commit('prop/loading_spinner_show');
                let post_data = this.modal_item;
                post_data.page = this.page;
                post_data.per_page = this.per_page;
                this.$axios.post('/admin/news_edit_item', post_data)
                    .then((res) => {
                        if (res.data.status == 'success') {
                            this.items = res.data.items;
                            this.number_of_pages = res.data.number_of_pages;
                            this.showModalMessage(res.data.message, 'text-success');
                        } else if (res.data.status == 'bad_data') {
                            this.showModalMessage(res.data.message, 'text-danger');
                        }
                        this.$store.commit('prop/loading_spinner_hide');
                    })
                    .catch((error) => {
                        // eslint-disable-next-line
                        console.log(error);
                        this.$store.commit('prop/loading_spinner_hide');
                    });
            },
        },
        watch: {
            '$route'() {
                this.loadData();
            },
        },
        created() {
            this.loadData();
        }
    }
</script>

<style scoped>

</style>