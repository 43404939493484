<template>
    <div>
        <h5>412 Условие ложно.</h5>
    </div>
</template>

<script>
    export default {
        name: "Page_412"
    }
</script>

<style scoped>

</style>