<template>
    <div class="m-3">
        <div class="row m-2 d-flex justify-content-start">
            <div class="col-9 d-inline-flex">
                <h3>Баланс по годам</h3>
            </div>

            <div class="col-3 text-right d-inline-flex">
                <b-form-group label="Год:"
                              label-cols-sm="4"
                              label-cols-lg="3"
                              content-cols-sm
                              content-cols-lg="9"
                >
                    <b-form-select v-model="select_year"
                                   :options="year_options"
                                   value-field="value"
                                   text-field="value"
                                   required>
                    </b-form-select>
                </b-form-group>
            </div>
        </div>

        <b-card-group deck class="mb-4">
            <b-card header="Январь" class="text-center">
                <b-card-text>{{items[1]}}</b-card-text>
            </b-card>
            <b-card header="Февраль" class="text-center">
                <b-card-text>{{items[2]}}</b-card-text>
            </b-card>
            <b-card header="Март" class="text-center">
                <b-card-text>{{items[3]}}</b-card-text>
            </b-card>
            <b-card header="Апрель" class="text-center">
                <b-card-text>{{items[4]}}</b-card-text>
            </b-card>
            <b-card header="Май" class="text-center">
                <b-card-text>{{items[5]}}</b-card-text>
            </b-card>
            <b-card header="Июнь" class="text-center">
                <b-card-text>{{items[6]}}</b-card-text>
            </b-card>
        </b-card-group>
        <b-card-group deck class="mb-4">
            <b-card header="Июль" class="text-center">
                <b-card-text>{{items[7]}}</b-card-text>
            </b-card>
            <b-card header="Август" class="text-center">
                <b-card-text>{{items[8]}}</b-card-text>
            </b-card>
            <b-card header="Сентябрь" class="text-center">
                <b-card-text>{{items[9]}}</b-card-text>
            </b-card>
            <b-card header="Октябрь" class="text-center">
                <b-card-text>{{items[10]}}</b-card-text>
            </b-card>
            <b-card header="Ноябрь" class="text-center">
                <b-card-text>{{items[11]}}</b-card-text>
            </b-card>
            <b-card header="Декабрь" class="text-center">
                <b-card-text>{{items[12]}}</b-card-text>
            </b-card>
        </b-card-group>
        <b-card header="ИТОГО" bg-variant="warning" text-variant="white" class="text-center">
            <b-card-text>{{total_balance}}</b-card-text>
        </b-card>
    </div>
</template>

<script>
    export default {
        name: "Balance",
        data() {
            return {
                items: {
                    1: null,
                    2: null,
                    3: null,
                    4: null,
                    5: null,
                    6: null,
                    7: null,
                    8: null,
                    9: null,
                    10: null,
                    11: null,
                    12: null,
                },
                total_balance: null,
                year_options: [],
                select_year: null,
                is_first_load_data: true
            }
        },
        methods: {
            loadData() {
                this.$store.commit('prop/loading_spinner_show');
                this.$axios.post('/user/balance_default')
                    .then((res) => {
                        this.items = res.data.items;
                        this.total_balance = res.data.total_balance;
                        this.year_options = res.data.year_options;
                        this.select_year = this.year_options[this.year_options.length - 1].value;
                        this.is_first_load_data = false;
                        this.$store.commit('prop/loading_spinner_hide');
                    })
                    .catch((error) => {
                        // eslint-disable-next-line
                        console.log(error);
                        this.$store.commit('prop/loading_spinner_hide');
                    });
            },
            get_new_data(year) {
                this.$store.commit('prop/loading_spinner_show');
                this.$axios.post('/user/balance_by_year', {year: year})
                    .then((res) => {
                        this.items = res.data.items;
                        this.total_balance = res.data.total_balance;
                        this.$store.commit('prop/loading_spinner_hide');
                    })
                    .catch((error) => {
                        // eslint-disable-next-line
                        console.log(error);
                        this.$store.commit('prop/loading_spinner_hide');
                    });
            }
        },
        watch: {
            'select_year': function (val) {
                if (!this.is_first_load_data){
                    this.get_new_data(val);
                }
            }
        },
        created() {
            this.loadData();
        }
    }
</script>

<style scoped>

</style>