<template>
    <div class="m-3">
        <div class="row m-2 d-flex justify-content-start">
            <div class="col-6 d-inline-flex">
                <h3>Призы</h3>
            </div>
            <div class="col-6 text-right justify-content-end">
                <b-button variant="outline-secondary" @click="showModalAdd" class="m-2">Добавить приз
                </b-button>
            </div>
        </div>

        <b-table striped :items="items" :fields="fields" primary-key="id"
                 head-variant="dark"
                 table-variant="light"
                 :tbody-transition-props="{name:'flip-list'}"
        >
            <template #cell(id)="{item}">
                {{ item.id }}
                <b-icon icon="window" variant="primary" @click="showEditModal(item)" style="cursor: pointer;">
                </b-icon>
            </template>
            <template #cell(active)="data">
                {{ data.value?'Текущий':'Заблокирован' }}
            </template>
        </b-table>

        <b-modal :title="modal_item.id != null ?'Редактировать приз - ' + modal_item.id : 'Добавить приз'"
                 ref="modalItem" size="xl"
                 no-close-on-backdrop no-close-on-esc hide-header-close hide-footer>
            <b-form @submit.prevent="submitModalForm">
                <div class="row">
                    <div class="col-4">
                        <b-form-group label="Название">
                            <b-form-input type="text"
                                          v-model="modal_item.name"
                                          maxlength="255"
                                          placeholder="Название"
                                          required>
                            </b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-4">
                        <b-form-group label="Стоимость"
                                      label-for="form-title-input">
                            <b-form-input type="number" min="0" step="1"
                                          v-model="modal_item.cost"
                                          required>
                            </b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-4">
                        <b-form-group label="Статус">
                            <b-form-radio-group v-model="modal_item.active" required>
                                <b-form-radio v-bind:value="true">Текущий</b-form-radio>
                                <b-form-radio v-bind:value="false">Заблокирован</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <b-form-group label="Описание">
                            <b-form-input type="text"
                                          v-model="modal_item.description"
                                          maxlength="255"
                                          placeholder="Описание"
                                          required>
                            </b-form-input>
                        </b-form-group>
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <b-form-group label="Выберите регионы, которым будет показываться приз">
                            <b-form-select v-model="modal_item.regions"
                                           :options="region_options"
                                           multiple
                                           :select-size="8"
                                           class="mb-3"
                                           value-field="id"
                                           text-field="name">
                            </b-form-select>
                        </b-form-group>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <b-form-group label="Картинка для приза"
                                      description="Придерживайтесь пропорций картинок других призов. Новая картинка заменит старую.">
                            <b-form-file
                                    v-model="item_file_send"
                                    placeholder="Выберите файл или перетащите в это поле."
                                    :required="modal_item.id == null"
                            ></b-form-file>
                        </b-form-group>
                    </div>
                </div>
                <template v-if="modal_item.id != null">
                    <div class="row mb-2" v-show="modal_item.file != null">
                        <div class="col-12">Текущий файл
                            <a href="#" @click="getFile(modal_item.id, modal_item.file)" class="m-1">{{
                                modal_item.file}}</a>
                        </div>
                    </div>
                </template>

                <transition enter-active-class="animated flipInX"
                            leave-active-class="animated flipOutX">
                    <div v-show="message.show" class="mb-2">
                            <span :class="message.classText">
                            {{message.text}}
                            </span>
                    </div>
                </transition>
                <div class="modal-footer m-2">
                    <b-button type="submit" variant="primary">{{ modal_item.id != null ?'Редактировать' : 'Добавить'}}
                    </b-button>
                    <b-button variant="secondary" @click="hideModalItem">Закрыть</b-button>
                </div>
            </b-form>
        </b-modal>
    </div>
</template>

<script>
    export default {
        name: "APrizes",
        data() {
            return {
                items: [],
                region_options: [],
                message: {
                    text: null,
                    show: false,
                    classText: null
                },
                item_file_send: null,
                fields: [
                    {
                        label: "№",
                        key: 'id',
                        sortable: true
                    },
                    {
                        label: "Название",
                        key: 'name',
                        sortable: true
                    },
                    {
                        label: "Стоимость",
                        key: 'cost',
                        sortable: true
                    },
                    {
                        label: "Описание",
                        key: 'description',
                        sortable: true
                    },
                    {
                        label: "Статус",
                        key: 'active',
                        sortable: false
                    },
                ],
                modal_item: {
                    id: null,
                    name: null,
                    cost: null,
                    description: null,
                    file: null,
                    active: null,
                    regions: [],
                },
            }
        },
        methods: {
            loadData() {
                this.$store.commit('prop/loading_spinner_show');
                this.$axios.post('/admin/prizes/data_for_page')
                    .then((res) => {
                        this.items = res.data.items;
                        this.region_options = res.data.region_options;
                        this.$store.commit('prop/loading_spinner_hide');
                    })
                    .catch((error) => {
                        // eslint-disable-next-line
                        console.log(error);
                        this.$store.commit('prop/loading_spinner_hide');
                    });
            },
            showMessage(text, classText) {
                this.message = {
                    text: text,
                    classText: classText,
                    show: true
                }
            },
            showModalAdd() {
                this.message.show = false;
                this.item_file_send = null;
                this.modal_item = {
                    id: null,
                    name: null,
                    cost: null,
                    description: null,
                    file: null,
                    active: null,
                    regions: [],
                };
                this.$refs['modalItem'].show();
            },
            showEditModal(item) {
                this.modal_item = JSON.parse(JSON.stringify(item));
                this.$refs['modalItem'].show();
            },
            hideModalItem() {
                this.$refs['modalItem'].hide();
            },
            submitModalForm() {
                if (this.modal_item.id != null) {
                    this.editPrize()
                } else {
                    this.addPrize();
                }
            },
            editPrize() {
                this.message.show = false;
                this.$store.commit('prop/loading_spinner_show');

                let formData = new FormData();
                formData.append('item', JSON.stringify(this.modal_item));
                formData.append('file', this.item_file_send);
                this.$axios.post('/admin/prizes/edit_item', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then((res) => {
                        if (res.data.status == 'success') {
                            this.item_file_send = null;
                            this.items = res.data.items;
                            this.modal_item.file = res.data.filename;
                            this.showMessage(res.data.message, 'text-success');
                        } else if (res.data.status == 'bad_data') {
                            this.showMessage(res.data.message, 'text-danger');
                        }
                        this.$store.commit('prop/loading_spinner_hide');
                    })
                    .catch((error) => {
                        // eslint-disable-next-line
                        console.log(error);
                        this.$store.commit('prop/loading_spinner_hide');
                    });
            },
            addPrize() {
                this.message.show = false;
                this.$store.commit('prop/loading_spinner_show');

                let formData = new FormData();
                formData.append('item', JSON.stringify(this.modal_item));
                formData.append('file', this.item_file_send);
                this.$axios.post('/admin/prizes/add_item', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then((res) => {
                        if (res.data.status == 'success') {
                            this.item_file_send = null;
                            this.modal_item = {
                                id: null,
                                name: null,
                                cost: null,
                                description: null,
                                file: null,
                                active: null,
                                regions: [],
                            };
                            this.items = res.data.items;
                            this.showMessage(res.data.message, 'text-success');
                        } else if (res.data.status == 'bad_data') {
                            this.showMessage(res.data.message, 'text-danger');
                        }
                        this.$store.commit('prop/loading_spinner_hide');
                    })
                    .catch((error) => {
                        // eslint-disable-next-line
                        console.log(error);
                        this.$store.commit('prop/loading_spinner_hide');
                    });
            },
            getFile(item_id, item_file) {
                this.$axios.get('/files_for_site/prize/' + item_id + '/' + item_file,
                    {responseType: 'blob'})
                    .then((response) => {
                        this.$fileDownload(response.data, item_file);
                    });
            }
        },
        created() {
            this.loadData();
        }
    }
</script>

<style scoped>

</style>