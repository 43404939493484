<template>
    <div class="m-2">
        IndexAdmin
    </div>
</template>

<script>
    export default {
        name: "IndexAdmin"
    }
</script>

<style scoped>

</style>