<template>
    <div class="m-3">
        <h3>Заявки на призы</h3>

        <transition name="fade">
            <div class="m-1 border border-success" v-show="is_show_filter">
                <div class="row p-2">
                    <div class="col-4">
                        <b-form-group label="id Заявки:"
                                      label-for="form-title-input">
                            <b-form-input type="number" min="1" step="1"
                                          v-model="filter.item_id">
                            </b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-4">
                        <b-form-group label="id Пользователя:"
                                      label-for="form-title-input">
                            <b-form-input type="number" min="1" step="1"
                                          v-model="filter.user_id">
                            </b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-4">
                        <b-form-group label="ФИО Пользователя"
                                      label-for="form-title-input">
                            <b-form-input type="text"
                                          v-model="filter.fio"
                                          maxlength="255">
                            </b-form-input>
                        </b-form-group>
                    </div>
                </div>
                <div class="row p-2">
                    <div class="col-3">
                        <b-form-group label="Кол-во на странице:"
                                      label-for="form-title-input">
                            <b-form-select v-model="per_page"
                                           :options="per_page_options"
                                           class="mb-3"
                                           value-field="value"
                                           text-field="text">
                            </b-form-select>
                        </b-form-group>
                    </div>
                    <div class="col-9 align-items-center">
                        <p class="text-right">
                            <b-button variant="secondary m-2" @click="cleanFilter">Очистить фильтр</b-button>
                            <b-button variant="secondary m-2" @click="setFilter">Применить фильтр</b-button>
                        </p>
                    </div>
                </div>
            </div>
        </transition>
        <div class="strike mb-1">
                    <span><a href="#"
                             @click="is_show_filter=!is_show_filter">настройки фильтра</a></span>
        </div>

        <b-table striped :items="items" :fields="fields" primary-key="id"
                 head-variant="dark"
                 table-variant="light"
                 :tbody-transition-props="{name:'flip-list'}"
                 :tbody-tr-class="rowClass"
        >
            <template #cell(id)="{item}">
                {{ item.id }}
                <b-icon icon="window" variant="primary" @click="showEditModal(item)" style="cursor: pointer;">
                </b-icon>
            </template>
            <template #cell(create_dt)="data">
                {{ $moment(data.value).format('ll')}}
            </template>
            <template #cell(fio)="data">
                {{ user_by_id[data.item.user_id]}}
            </template>
            <template #cell(status_id)="data">
                {{ status_by_id[data.value]['name']}}
            </template>
        </b-table>

        <div class="row mt-3 ml-3">
            <div class="col">
                <b-pagination-nav
                        :link-gen="linkGen"
                        :number-of-pages="number_of_pages"
                        use-router
                        align="right">
                </b-pagination-nav>
            </div>
        </div>

        <b-modal :title="'Заявка № ' + item_modal.id" ref="modalItem" size="xl"
                 no-close-on-backdrop no-close-on-esc hide-header-close hide-footer
                 body-bg-variant="light" header-bg-variant="warning">
            <div class="row m-2">
                <div class="col-3">
                    <b-form-group label="Дата">
                                    <span class="ml-2 font-weight-bold">
                                        {{ $moment(item_modal.create_dt).format('ll')}}
                                    </span>
                    </b-form-group>
                </div>
                <div class="col-3">
                    <b-form-group label="Пользователь">
                                    <span class="ml-2 font-weight-bold">
                                        id {{item_modal.user_id}} - {{item_modal.user_id == null ? '': user_by_id[item_modal.user_id] }}
                                    </span>
                    </b-form-group>
                </div>
                <div class="col-3">
                    <b-form-group label="Кол-во баллов">
                                    <span class="ml-2 font-weight-bold">
                                        {{item_modal.cost}}
                                    </span>
                    </b-form-group>
                </div>
                <div class="col-3">
                    <b-form-group label="Статус">
                        <template v-if="item_modal.status_id != 1">
                            <span class="ml-2 font-weight-bold">
                                {{ item_modal.status_id == null ? '': status_by_id[item_modal.status_id]['name']}}
                            </span>
                        </template>
                        <template v-else>
                            <b-form-select v-model="new_status_prize_application"
                                           :options="status_options"
                                           value-field="id"
                                           text-field="name"
                                           required>
                            </b-form-select>
                        </template>
                    </b-form-group>
                </div>
                <hr>
            </div>
            <hr>
            <div class="row m-2 font-weight-bold">
                <div class="col-3">Название</div>
                <div class="col-3">Кол-во</div>
                <div class="col-3">Стоимость</div>
                <div class="col-3">Итого</div>
            </div>
            <div class="row m-2" v-for="(p_count, p_id) in item_modal.prizes" v-bind:key="p_id">
                <p></p>
                <div class="col-3">
                    {{prizes_by_id[p_id].name}}
                    <b-badge variant="info"
                             v-b-popover.hover.top="prizes_by_id[p_id].description"
                             title="Описание">
                        info
                    </b-badge>
                </div>
                <div class="col-3">
                    {{p_count}}
                </div>
                <div class="col-3">
                    {{prizes_by_id[p_id].cost}}
                </div>
                <div class="col-3">{{prizes_by_id[p_id].cost * p_count}}</div>
            </div>
            <transition enter-active-class="animated flipInX"
                        leave-active-class="animated flipOutX">
                <div v-show="message.show" class="mb-2">
                            <span :class="message.classText">
                            {{message.text}}
                            </span>
                </div>
            </transition>
            <div class="modal-footer m-2">
                <b-button v-if="item_modal.status_id == 1" variant="success" @click="editPrizeApplication">Сохранить
                </b-button>
                <b-button variant="secondary" @click="hideEditModal">Закрыть</b-button>
            </div>
        </b-modal>

    </div>
</template>

<script>
    export default {
        name: "APrizeApplications",
        data() {
            return {
                items: [],
                page: null,
                per_page: 20,
                number_of_pages: 1,
                fields: [
                    {
                        label: "№",
                        key: 'id',
                        sortable: true
                    },
                    {
                        label: "Дата",
                        key: 'create_dt',
                        sortable: true
                    },
                    {
                        label: "id Пользователя",
                        key: 'user_id',
                        sortable: true
                    },
                    {
                        label: "Пользователь",
                        key: 'fio', sortable: true,
                        sortByFormatted: true,
                        formatter: (value, key, item) => {
                            return this.user_by_id[item.user_id];
                        },
                    },
                    {
                        label: "Баллы",
                        key: 'cost',
                        sortable: true
                    },
                    {
                        label: "Статус",
                        key: 'status_id',
                        sortable: false
                    },
                ],
                item_modal: {
                    id: null,
                    create_dt: null,
                    cost: null,
                    user_id: null,
                    status_id: null,
                    prizes: {},
                },
                user_by_id: {},
                status_by_id: {},
                prizes_by_id: {},
                new_status_prize_application: null,
                status_options: [],
                message: {
                    text: null,
                    show: false,
                    classText: null
                },
                is_show_filter: false,
                filter: {
                    item_id: null,
                    user_id: null,
                    fio: null,
                },
                per_page_options: [
                    {text: 20, value: 20},
                    {text: 40, value: 40},
                    {text: 60, value: 60},
                    {text: 80, value: 80},
                    {text: 100, value: 100},
                ],
            }
        },
        methods: {
            loadData() {
                this.$store.commit('prop/loading_spinner_show');
                this.page = this.$route.params.page;
                this.$axios.post('/admin/prize_applications_data_for_page', {
                    page: this.page,
                    per_page: this.per_page,
                    f_item_id: this.filter.item_id,
                    f_user_id: this.filter.user_id,
                    f_fio: this.filter.fio,
                })
                    .then((res) => {
                        this.items = res.data.items;
                        this.number_of_pages = res.data.number_of_pages;
                        this.user_by_id = res.data.user_by_id;
                        this.status_by_id = res.data.status_by_id;
                        this.prizes_by_id = res.data.prizes_by_id;
                        this.status_options = res.data.status_options;
                        this.$store.commit('prop/loading_spinner_hide');
                    })
                    .catch((error) => {
                        // eslint-disable-next-line
                        console.log(error);
                        this.$store.commit('prop/loading_spinner_hide');
                    });
            },
            showMessage(text, classText) {
                this.message = {
                    text: text,
                    classText: classText,
                    show: true
                }
            },
            linkGen(pageNum) {
                return `/admin/prize_applications/${pageNum}`;
            },
            showEditModal(item) {
                this.message.show = false;
                this.item_modal = JSON.parse(JSON.stringify(item));
                this.new_status_prize_application = this.item_modal.status_id;
                this.$refs['modalItem'].show();
            },
            hideEditModal() {
                this.$refs['modalItem'].hide();
            },
            rowClass(item) {
                if (item['status_id'] === 2) {
                    return 'table-warning'
                } else if (item['status_id'] === 3) {
                    return 'table-danger'
                }
                else if (item['status_id'] === 4) {
                    return 'table-success'
                }
            },
            editPrizeApplication() {
                this.message.show = false;
                if (this.new_status_prize_application == 1) {
                    this.showMessage('Нужно выбрать другой статус', 'text-danger');
                    return
                }

                this.$store.commit('prop/loading_spinner_show');
                this.$axios.post('/admin/prize_application_new_status', {
                    pa_id: this.item_modal.id,
                    pa_status: this.new_status_prize_application,
                })
                    .then((res) => {
                        if (res.data.status == 'success') {
                            this.item_modal.status_id = res.data.new_status;
                            let pa_id = this.item_modal.id;
                            this.items.forEach(function (item) {
                                if (item.id == pa_id) {
                                    item.status_id = res.data.new_status;
                                }
                            });
                            this.showMessage(res.data.message, 'text-success');
                        } else if (res.data.status == 'bad_data') {
                            this.showMessage(res.data.message, 'text-danger');
                        }
                        this.$store.commit('prop/loading_spinner_hide');
                    })
                    .catch((error) => {
                        // eslint-disable-next-line
                        console.log(error);
                        this.$store.commit('prop/loading_spinner_hide');
                    });
            },
            setFilter() {
                if (this.page == 1) {
                    this.loadData();
                } else {
                    this.$router.push('/admin/prize_applications/1');
                }
            },
            cleanFilter() {
                this.filter = {
                    item_id: null,
                    user_id: null,
                    fio: null,
                };
                this.per_page = 20;
            },
        },
        watch: {
            '$route'() {
                this.loadData();
            },
        },
        created() {
            this.loadData();
        }
    }
</script>

<style scoped>

</style>