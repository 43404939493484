<template>
    <div class="m-3">
        <div class="row m-2 d-flex justify-content-start">
            <div class="col-6 d-inline-flex">
                <h3>Пользователи</h3>
            </div>
            <div class="col-6 text-right justify-content-end">
                <!--<b-button variant="outline-secondary" @click="showEditModal">Внести коррекцию</b-button>-->
                <b-button variant="outline-secondary" @click="showModalAddRole2" class="m-2">Добавить пользователя
                </b-button>
                <b-button variant="outline-secondary" @click="showModalAddRole3" class="m-2">Добавить менеджера
                </b-button>
            </div>
        </div>

        <transition name="fade">
            <div class="m-1 border border-success" v-show="is_show_filter">
                <div class="row p-2">
                    <div class="col-6">
                        <b-form-group label="id Пользователя:"
                                      label-for="form-title-input">
                            <b-form-input type="number" min="1" step="1"
                                          v-model="filter.user_id">
                            </b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-6">
                        <b-form-group label="ФИО Пользователя"
                                      label-for="form-title-input">
                            <b-form-input type="text"
                                          v-model="filter.fio"
                                          maxlength="255">
                            </b-form-input>
                        </b-form-group>
                    </div>
                </div>
                <div class="row p-2">
                    <div class="col-3">
                        <b-form-group label="Кол-во на странице:"
                                      label-for="form-title-input">
                            <b-form-select v-model="per_page"
                                           :options="per_page_options"
                                           class="mb-3"
                                           value-field="value"
                                           text-field="text">
                            </b-form-select>
                        </b-form-group>
                    </div>
                    <div class="col-9 align-items-center">
                        <p class="text-right">
                            <b-button variant="secondary m-2" @click="cleanFilter">Очистить фильтр</b-button>
                            <b-button variant="secondary m-2" @click="setFilter">Применить фильтр</b-button>
                        </p>
                    </div>
                </div>
            </div>
        </transition>
        <div class="strike mb-1">
                    <span><a href="#"
                             @click="is_show_filter=!is_show_filter">настройки фильтра</a></span>
        </div>

        <b-table striped :items="items" :fields="fields" primary-key="id"
                 head-variant="dark"
                 table-variant="light"
                 :tbody-transition-props="{name:'flip-list'}"
                 :tbody-tr-class="rowClass"
        >
            <template #cell(id)="{item}">
                {{ item.id }}
                <b-icon icon="window" variant="primary" @click="showEditModal(item)" style="cursor: pointer;">
                </b-icon>
            </template>
            <template #cell(fio)="data">
                {{ user_by_id[data.item.id] }}
            </template>
            <template #cell(role_id)="data">
                {{ role_by_id[data.value] }}
            </template>
            <template #cell(manager_id)="data">
                {{ data.item.role_id == 2 ? user_by_id[data.value]: '---' }}
            </template>
            <template #cell(balance)="data">
                {{ data.item.role_id == 2 ? data.value : '---' }}
            </template>
            <template #cell(is_male)="data">
                {{ data.value?'муж':'жен' }}
            </template>
            <template #cell(active)="data">
                {{ data.value?'Текущий':'Заблокирован' }}
            </template>
        </b-table>

        <div class="row mt-3 ml-3">
            <div class="col">
                <b-pagination-nav
                        :link-gen="linkGen"
                        :number-of-pages="number_of_pages"
                        use-router
                        align="right">
                </b-pagination-nav>
            </div>
        </div>

        <b-modal :title="item_modal.id == null ? 'Добавляем': 'Редактируем id - ' + item_modal.id" ref="modalItem"
                 size="xl"
                 no-close-on-backdrop no-close-on-esc hide-header-close hide-footer
                 body-bg-variant="light" header-bg-variant="warning">
            <b-form @submit.prevent="submitButtonModal">
                <h5 v-if="item_modal.role_id == 1">Администатор</h5>
                <h5 v-else-if="item_modal.role_id == 2">Проектировщик</h5>
                <h5 v-else>Менеджер</h5>
                <div class="row m-2">
                    <div class="col-4">
                        <b-form-group label="ФИО"
                                      label-for="form-title-input">
                            <b-form-input type="text"
                                          v-model="item_modal.fio"
                                          maxlength="255"
                                          required
                                          placeholder="ФИО">
                            </b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-3">
                        <b-form-group label="Логин"
                                      label-for="form-title-input">
                            <b-form-input type="text"
                                          v-model="item_modal.login"
                                          maxlength="255"
                                          required
                                          placeholder="Логин">
                            </b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-3">
                        <b-form-group label="Пароль"
                                      label-for="form-title-input">
                            <b-form-input type="text"
                                          v-model="item_modal.password"
                                          maxlength="255"
                                          required
                                          placeholder="Пароль">
                            </b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-2">
                        <b-form-group label="Статус">
                            <b-form-radio-group v-model="item_modal.active" required>
                                <b-form-radio v-bind:value="true">Активен</b-form-radio>
                                <b-form-radio v-bind:value="false">Заблокирован</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                    </div>
                </div>
                <div class="row m-2">
                    <div class="col-3">
                        <b-form-group label="Почта"
                                      label-for="form-title-input">
                            <b-form-input type="text"
                                          v-model="item_modal.mail"
                                          maxlength="255"
                                          required
                                          placeholder="Почта">
                            </b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-3">
                        <b-form-group label="Телефон"
                                      label-for="form-title-input">
                            <b-form-input type="text"
                                          v-model="item_modal.phone"
                                          maxlength="255"
                                          required
                                          placeholder="Телефон">
                            </b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-3">
                        <b-form-group label="Дата рождения">
                            <b-input-group label="Дата рождения">
                                <b-form-input
                                        id="example-input"
                                        v-model="item_modal.birthday"
                                        type="text"
                                        placeholder="YYYY-MM-DD"
                                        autocomplete="off"
                                        required
                                ></b-form-input>
                                <b-input-group-append>
                                    <b-form-datepicker
                                            v-model="item_modal.birthday"
                                            button-only
                                            right
                                            locale="ru" start-weekday="1"
                                            aria-controls="example-input"
                                            required
                                    ></b-form-datepicker>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </div>
                    <div class="col-3">
                        <b-form-group label="Пол">
                            <b-form-radio-group v-model="item_modal.is_male" required>
                                <b-form-radio v-bind:value="true">Муж</b-form-radio>
                                <b-form-radio v-bind:value="false">Жен</b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                    </div>
                </div>
                <div class="row m-2">
                    <div class="col-3">
                        <b-form-group label="Регион:"
                                      label-for="form-title-input">
                            <b-form-select v-model="item_modal.region_id"
                                           :options="regions_options"
                                           value-field="id"
                                           text-field="name">
                            </b-form-select>
                        </b-form-group>
                    </div>
                    <div class="col-3">
                        <b-form-group label="Город:"
                                      label-for="form-title-input">
                            <b-form-select v-model="item_modal.city_id"
                                           :options="cities_by_region_id_options[item_modal.region_id]"
                                           value-field="id"
                                           text-field="name"
                                           required>
                            </b-form-select>
                        </b-form-group>
                    </div>
                    <div class="col-3">
                        <b-form-group label="Компания:"
                                      label-for="form-title-input">
                            <b-form-select v-model="item_modal.company_id"
                                           :options="company_options"
                                           value-field="id"
                                           text-field="name"
                                           required>
                            </b-form-select>
                        </b-form-group>
                    </div>
                    <div class="col-3">
                        <b-form-group label="Должность:"
                                      label-for="form-title-input">
                            <b-form-select v-model="item_modal.official_position_id"
                                           :options="op_options"
                                           value-field="id"
                                           text-field="name"
                                           required>
                            </b-form-select>
                        </b-form-group>
                    </div>
                </div>
                <template v-if="item_modal.role_id == 2">
                    <div class="row m-2">
                        <div class="col-6">
                            <b-form-group :label="'Менеджер: ' + item_modal.manager_id"
                                          label-for="form-title-input">
                                <b-form-select v-model="item_modal.manager_id"
                                               :options="manager_options"
                                               value-field="id"
                                               text-field="fio"
                                               required>
                                </b-form-select>
                            </b-form-group>
                        </div>
                        <div class="col-6">
                            <b-form-group label="Баланс">
                                    <span class="ml-2 font-weight-bold">
                                        {{ item_modal.balance }}
                                    </span>
                            </b-form-group>
                        </div>
                    </div>
                </template>
                <transition enter-active-class="animated flipInX"
                            leave-active-class="animated flipOutX">
                    <div v-show="message.show" class="mb-2">
                            <span :class="message.classText">
                            {{message.text}}
                            </span>
                    </div>
                </transition>
                <div class="modal-footer m-2">
                    <b-button variant="success" type="submit">Сохранить</b-button>
                    <b-button variant="secondary" @click="hideEditModal">Закрыть</b-button>
                </div>
            </b-form>
        </b-modal>
    </div>
</template>

<script>
    export default {
        name: "AUsers",
        data() {
            return {
                items: [],
                page: null,
                per_page: 20,
                number_of_pages: 1,
                fields: [
                    {
                        label: "id Пользователя",
                        key: 'id',
                        sortable: true
                    },
                    {
                        label: "Пользователь",
                        key: 'fio', sortable: true,
                        sortByFormatted: true,
                        formatter: (value, key, item) => {
                            return this.user_by_id[item.id];
                        },
                    },
                    {
                        label: "Телефон",
                        key: 'phone',
                        sortable: true
                    },
                    {
                        label: "Почта",
                        key: 'mail',
                        sortable: true
                    },
                    {
                        label: "Роль",
                        key: 'role_id',
                        sortable: true
                    },
                    {
                        label: "Менеджер",
                        key: 'manager_id',
                        sortable: true
                    },
                    {
                        label: "Баллы",
                        key: 'balance',
                        sortable: true
                    },
                    {
                        label: "Пол",
                        key: 'is_male',
                        sortable: true
                    },
                    {
                        label: "Статус",
                        key: 'active',
                        sortable: true
                    },
                ],
                item_modal: {
                    id: null,
                    login: null,
                    password: null,
                    mail: null,
                    fio: null,
                    birthday: null,
                    phone: null,
                    balance: null,
                    create_dt: null,
                    active: null,
                    role_id: null,
                    manager_id: null,
                    company_id: null,
                    official_position_id: null,
                    city_id: null,
                    region_id: null,
                    is_male: null,
                },
                user_by_id: {},
                message: {
                    text: null,
                    show: false,
                    classText: null
                },
                manager_options: [],
                role_by_id: {},
                cities_by_region_id_options: {},
                regions_options: [],
                company_options: [],
                op_options: [],
                is_show_filter: false,
                filter: {
                    user_id: null,
                    fio: null,
                },
                per_page_options: [
                    {text: 20, value: 20},
                    {text: 40, value: 40},
                    {text: 60, value: 60},
                    {text: 80, value: 80},
                    {text: 100, value: 100},
                ],
            }
        },
        methods: {
            loadData() {
                this.$store.commit('prop/loading_spinner_show');
                this.page = this.$route.params.page;
                this.$axios.post('/admin/users_data_for_page', {
                    page: this.page,
                    per_page: this.per_page,
                    f_user_id: this.filter.user_id,
                    f_fio: this.filter.fio,
                })
                    .then((res) => {
                        this.items = res.data.items;
                        this.number_of_pages = res.data.number_of_pages;
                        this.user_by_id = res.data.user_by_id;
                        this.manager_options = res.data.manager_options;
                        this.role_by_id = res.data.role_by_id;
                        this.regions_options = res.data.regions_options;
                        this.cities_by_region_id_options = res.data.cities_by_region_id_options;
                        this.op_options = res.data.op_options;
                        this.company_options = res.data.company_options;
                        this.$store.commit('prop/loading_spinner_hide');
                    })
                    .catch((error) => {
                        // eslint-disable-next-line
                        console.log(error);
                        this.$store.commit('prop/loading_spinner_hide');
                    });
            },
            showModalMessage(text, classText) {
                this.message = {
                    text: text,
                    classText: classText,
                    show: true
                }
            },
            linkGen(pageNum) {
                return `/admin/users/${pageNum}`;
            },
            showEditModal(item) {
                this.item_modal = JSON.parse(JSON.stringify(item));
                this.message.show = false;
                this.$refs['modalItem'].show();
            },
            submitButtonModal() {
                if (this.item_modal.id == null) {
                    this.addUser();
                } else {
                    this.editUser();
                }
            },
            showModalAddRole2() {
                this.item_modal = {
                    id: null,
                    login: null,
                    password: null,
                    mail: null,
                    fio: null,
                    birthday: null,
                    phone: null,
                    balance: null,
                    create_dt: null,
                    active: null,
                    role_id: 2,
                    manager_id: null,
                    company_id: null,
                    official_position_id: null,
                    city_id: null,
                    region_id: null,
                    is_male: null,
                };
                this.message.show = false;
                this.set_login_and_psw();
                this.$refs['modalItem'].show();
            },
            showModalAddRole3() {
                this.item_modal = {
                    id: null,
                    login: null,
                    password: null,
                    mail: null,
                    fio: null,
                    birthday: null,
                    phone: null,
                    balance: null,
                    create_dt: null,
                    active: null,
                    role_id: 3,
                    manager_id: null,
                    company_id: null,
                    official_position_id: null,
                    city_id: null,
                    region_id: null,
                    is_male: null,
                };

                this.message.show = false;
                this.set_login_and_psw();
                this.$refs['modalItem'].show();
            },
            set_login_and_psw(){
                // Получим и подставим в форму сгенерированный логин и пароль
                this.$axios.post('/admin/users_generate_lp')
                    .then((res) => {
                        this.item_modal.login = res.data.login;
                        this.item_modal.password = res.data.psw;
                        this.$store.commit('prop/loading_spinner_hide');
                    })
                    .catch((error) => {
                        // eslint-disable-next-line
                        console.log(error);
                        this.$store.commit('prop/loading_spinner_hide');
                    });
            },
            addUser() {
                this.message.show = false;
                this.$store.commit('prop/loading_spinner_show');
                let post_data = this.item_modal;
                post_data.page = this.page;
                post_data.per_page = this.per_page;
                this.$axios.post('/admin/users_add_user', post_data)
                    .then((res) => {
                        if (res.data.status == 'success') {
                            this.items = res.data.items;
                            this.number_of_pages = res.data.number_of_pages;
                            this.showModalMessage(res.data.message, 'text-success');
                        } else if (res.data.status == 'bad_data') {
                            this.showModalMessage(res.data.message, 'text-danger');
                        }
                        this.$store.commit('prop/loading_spinner_hide');
                    })
                    .catch((error) => {
                        // eslint-disable-next-line
                        console.log(error);
                        this.$store.commit('prop/loading_spinner_hide');
                    });
            },
            editUser() {
                this.message.show = false;
                this.$store.commit('prop/loading_spinner_show');
                this.$axios.post('/admin/users_edit_user', this.item_modal)
                    .then((res) => {
                        if (res.data.status == 'success') {
                            for (let index = 0; index < this.items.length; ++index) {
                                if (this.items[index].id === this.item_modal.id) {
                                    this.$set(this.items, index, this.item_modal)
                                }
                            }
                            this.showModalMessage(res.data.message, 'text-success');
                        } else if (res.data.status == 'bad_data') {
                            this.showModalMessage(res.data.message, 'text-danger');
                        }
                        this.$store.commit('prop/loading_spinner_hide');
                    })
                    .catch((error) => {
                        // eslint-disable-next-line
                        console.log(error);
                        this.$store.commit('prop/loading_spinner_hide');
                    });
            },
            hideEditModal() {
                this.$refs['modalItem'].hide();
            },
            rowClass(item) {
                if (item['role_id'] === 2) {
                    return 'table-warning'
                } else if (item['role_id'] === 3) {
                    return 'table-danger'
                }
                else if (item['role_id'] === 1) {
                    return 'table-success'
                }
            },
            setFilter() {
                if (this.page == 1) {
                    this.loadData();
                } else {
                    this.$router.push('/admin/users/1');
                }
            },
            cleanFilter() {
                this.filter = {
                    user_id: null,
                    fio: null,
                };
                this.per_page = 20;
            },
        },
        watch: {
            '$route'() {
                this.loadData();
            },
        },
        created() {
            this.loadData();
        }
    }
</script>

<style scoped>

</style>