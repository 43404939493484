<template>
    <div class="m-3">
        <div class="row m-2 d-flex justify-content-start">
            <div class="col-9 d-inline-flex">
                <h3>Призы</h3>

            </div>

            <div class="col-3 text-right d-inline-flex">
                <h5 class="m-2">Общая сумма баллов: {{total_cost}}</h5>
                <b-button size="sm" variant="outline-dark" @click="get_prizes">
                    Получить призы
                </b-button>
            </div>
        </div>
        <div class="row m-2">
            <div class="col">
                <template v-if="count_pa > 0">
                    <b>Заявок в работе: </b>{{count_pa}}<br>
                    <b>Общее кол-во баллов по заявкам:</b> {{total_cost_pa}}
                </template>
            </div>
        </div>

        <b-card-group deck>
            <b-card v-for="item in items" v-bind:key="item.id"
                    style="max-width: 300px; min-width: 300px;"
                    class="p-1"
                    bg-variant="warning"
                    :title="item.name"
                    :img-src="get_img_url(item.id, item.file)" img-alt="Image" img-top>
                <b-card-text>
                    <p>
                        {{item.description}}
                    </p>
                    <p class="font-weight-bold  text-right">
                        Баллы: <span class="font-weight-normal">{{item.cost}}</span>
                    </p>
                </b-card-text>
                <template #footer>
                    <b-form-group label="Кол-во" label-align-sm="right" :label-for="'item_count_' + item.id"
                                  label-cols="3">
                        <b-form-input type="number" step="1" min="0"
                                      v-model="item.count"
                                      :id="'item_count_' + item.id"
                                      placeholder="...">
                        </b-form-input>
                    </b-form-group>
                </template>
            </b-card>
        </b-card-group>

        <b-modal title="Сообщение" ref="modalMessage" header-bg-variant="warning"
                 no-close-on-backdrop no-close-on-esc hide-header-close hide-footer>
            <br><h6 :class="modal_message_class">{{modal_message}}</h6><br>
            <div class="modal-footer m-2">
                <b-button variant="secondary" @click="hideModalMessage">Закрыть</b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>

    export default {
        name: "Prizes",
        data() {
            return {
                groups: [],
                items: [],
                base_url: null,
                name: null,
                total_cost_pa: 0,
                count_pa: 0,
                modal_message: null,
                modal_message_class: null,
            }
        },
        methods: {
            loadData() {
                this.$store.commit('prop/loading_spinner_show');
                this.page = this.$route.params.page;
                this.$axios.post('/user/prizes_data_for_page')
                    .then((res) => {
                        this.total_cost_pa = res.data.total_cost_pa;
                        this.count_pa = res.data.count_pa;
                        this.items = res.data.items;
                        this.$store.commit('prop/loading_spinner_hide');
                    })
                    .catch((error) => {
                        // eslint-disable-next-line
                        console.log(error);
                        this.$store.commit('prop/loading_spinner_hide');
                    });
            },
            get_img_url(id, file_name) {
                let base_url = this.$axios.defaults.baseURL + name;
                return base_url + '/files_for_site/prize/' + id + '/' + file_name
            },
            get_prizes() {
                if (this.total_cost == 0) {
                    this.showModalMessage('Выберите подарки.', '');
                    return
                }

                this.$store.commit('prop/loading_spinner_show');
                this.page = this.$route.params.page;
                this.$axios.post('/user/get_prizes', {'prizes': this.items})
                    .then((res) => {
                        if (res.data.status == 'success') {
                            this.total_cost_pa = res.data.total_cost_pa;
                            this.count_pa = res.data.count_pa;
                            this.clean_counts();
                            this.showModalMessage(res.data.message, 'text-success');
                        } else if (res.data.status == 'bad_data') {
                            if ('new_balance' in res.data) {
                                this.$store.commit('auth/new_balance', {new_balance: res.data.new_balance})
                            }
                            this.showModalMessage(res.data.message, 'text-danger');
                        }
                        this.$store.commit('prop/loading_spinner_hide');
                    })
                    .catch((error) => {
                        // eslint-disable-next-line
                        console.log(error);
                        this.$store.commit('prop/loading_spinner_hide');
                    });

            },
            clean_counts() {
                this.items.forEach(function (item) {
                    item.count = 0;
                })
            },
            showModalMessage(message, text_class) {
                this.modal_message = message;
                this.modal_message_class = text_class;
                this.$refs['modalMessage'].show();
            },
            hideModalMessage() {
                this.$refs['modalMessage'].hide();
            },
        },
        computed: {
            total_cost: function () {
                let total = 0;
                // for (let item in this.items){
                //     total += item.cost * item.count;
                // }
                this.items.forEach(function (item) {
                    total += item.cost * item.count;
                });
                return total;
            }
        },
        created() {
            this.loadData();
        }
    }
</script>

<style scoped>
</style>