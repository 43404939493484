<template>
    <div class="m-3">
        <div class="row m-2 d-flex justify-content-start">
            <div class="col-9 d-inline-flex">
                <h3>Коррекции</h3>
            </div>
            <div class="col-3 text-right d-inline-flex justify-content-end">
                <b-button variant="outline-secondary" @click="showEditModal">Внести коррекцию</b-button>
            </div>
        </div>

        <transition name="fade">
            <div class="m-1 border border-success" v-show="is_show_filter">
                <div class="row p-2">
                    <div class="col-4">
                        <b-form-group label="id Коррекции:"
                                      label-for="form-title-input">
                            <b-form-input type="number" min="1" step="1"
                                          v-model="filter.correction_id">
                            </b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-4">
                        <b-form-group label="id Пользователя:"
                                      label-for="form-title-input">
                            <b-form-input type="number" min="1" step="1"
                                          v-model="filter.user_id">
                            </b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-4">
                        <b-form-group label="ФИО Пользователя"
                                      label-for="form-title-input">
                            <b-form-input type="text"
                                          v-model="filter.fio"
                                          maxlength="255">
                            </b-form-input>
                        </b-form-group>
                    </div>
                </div>
                <div class="row p-2">
                    <div class="col-3">
                        <b-form-group label="Кол-во на странице:"
                                      label-for="form-title-input">
                            <b-form-select v-model="per_page"
                                           :options="per_page_options"
                                           class="mb-3"
                                           value-field="value"
                                           text-field="text">
                            </b-form-select>
                        </b-form-group>
                    </div>
                    <div class="col-9 align-items-center">
                        <p class="text-right">
                            <b-button variant="secondary m-2" @click="cleanFilter">Очистить фильтр</b-button>
                            <b-button variant="secondary m-2" @click="setFilter">Применить фильтр</b-button>
                        </p>
                    </div>
                </div>
            </div>
        </transition>
        <div class="strike mb-1">
                    <span><a href="#"
                             @click="is_show_filter=!is_show_filter">настройки фильтра</a></span>
        </div>

        <b-table striped :items="items" :fields="fields" primary-key="id"
                 head-variant="dark"
                 table-variant="light"
                 :tbody-transition-props="{name:'flip-list'}"
        >
            <template #cell(create_dt)="data">
                {{ $moment(data.value).format('ll')}}
            </template>
            <template #cell(fio)="data">
                {{ user_by_id[data.item.user_id]}}
            </template>
        </b-table>

        <div class="row mt-3 ml-3">
            <div class="col">
                <b-pagination-nav
                        :link-gen="linkGen"
                        :number-of-pages="number_of_pages"
                        use-router
                        align="right">
                </b-pagination-nav>
            </div>
        </div>


        <b-modal title="Внести коррекцию" ref="modalItem" size="xl"
                 no-close-on-backdrop no-close-on-esc hide-header-close hide-footer
                 body-bg-variant="light" header-bg-variant="warning">
            <b-form @submit.prevent="addCorrection">
                <div class="row m-2">
                    <div class="col-6">
                        <b-form-group
                                :label="item_modal.user_id != null ? user_by_id[item_modal.user_id] : 'Выберите пользователя'"
                                label-for="form-title-input">
                            <b-form-select v-model="item_modal.user_id"
                                           :options="user_options"
                                           class="mb-3"
                                           value-field="id"
                                           text-field="id"
                                           required>
                                <template #first>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </div>
                    <div class="col-6">
                        <b-form-group label="Кол-во баллов:" aria-describedby="input-live-help"
                                      label-for="form-title-input">
                            <b-form-input type="number" step="1"
                                          v-model="item_modal.cost"
                                          placeholder="Кол-во баллов"
                                          required>
                            </b-form-input>
                            <b-form-text id="input-live-help">Положительое число чтобы прибавить, отрицательное чтобы
                                вычесть.
                            </b-form-text>
                        </b-form-group>
                    </div>
                </div>
                <div class="row m-2">
                    <div class="col">
                        <b-form-group label="Комментарии"
                                      label-for="form-title-input">
                            <b-form-textarea
                                    v-model="item_modal.comment"
                                    placeholder="Комментарии"
                                    maxlength="255"
                                    rows="3"
                                    max-rows="6"
                                    required
                            ></b-form-textarea>
                        </b-form-group>
                    </div>
                </div>

                <transition enter-active-class="animated flipInX"
                            leave-active-class="animated flipOutX">
                    <div v-show="message.show" class="mb-2">
                            <span :class="message.classText">
                            {{message.text}}
                            </span>
                    </div>
                </transition>
                <div class="modal-footer m-2">
                    <b-button variant="success" type="submit">Сохранить</b-button>
                    <b-button variant="secondary" @click="hideEditModal">Закрыть</b-button>
                </div>
            </b-form>
        </b-modal>
    </div>
</template>

<script>
    export default {
        name: "ACorrections",
        data() {
            return {
                items: [],
                filter: {
                    correction_id: null,
                    user_id: null,
                    fio: null,
                },
                per_page_options: [
                    {text: 20, value: 20},
                    {text: 40, value: 40},
                    {text: 60, value: 60},
                    {text: 80, value: 80},
                    {text: 100, value: 100},
                ],
                page: null,
                per_page: 20,
                number_of_pages: 1,
                fields: [
                    {
                        label: "№",
                        key: 'id',
                        sortable: true
                    },
                    {
                        label: "Дата",
                        key: 'create_dt',
                        sortable: true
                    },
                    {
                        label: "id Пользователя",
                        key: 'user_id',
                        sortable: true
                    },
                    {
                        label: "Пользователь",
                        key: 'fio', sortable: true,
                        sortByFormatted: true,
                        formatter: (value, key, item) => {
                            return this.user_by_id[item.user_id];
                        },
                    },
                    {
                        label: "Баллы",
                        key: 'cost',
                        sortable: true
                    },
                    {
                        label: "Комментарий",
                        key: 'comment',
                        sortable: false
                    },
                ],
                item_modal: {
                    user_id: null,
                    cost: null,
                    comment: null,
                },
                user_by_id: {},
                message: {
                    text: null,
                    show: false,
                    classText: null
                },
                user_options: [],
                is_show_filter: false
            }
        },
        methods: {
            loadData() {
                this.$store.commit('prop/loading_spinner_show');
                this.page = this.$route.params.page;
                this.$axios.post('/admin/corrections_data_for_page', {
                    page: this.page,
                    per_page: this.per_page,
                    f_correction_id: this.filter.correction_id,
                    f_user_id: this.filter.user_id,
                    f_fio: this.filter.fio,
                })
                    .then((res) => {
                        this.items = res.data.items;
                        this.number_of_pages = res.data.number_of_pages;
                        this.user_by_id = res.data.user_by_id;
                        this.user_options = res.data.user_options;
                        this.$store.commit('prop/loading_spinner_hide');
                    })
                    .catch((error) => {
                        // eslint-disable-next-line
                        console.log(error);
                        this.$store.commit('prop/loading_spinner_hide');
                    });
            },
            setFilter() {
                if (this.page == 1) {
                    this.loadData();
                } else {
                    this.$router.push('/admin/corrections/1');
                }
            },
            cleanFilter() {
                this.filter = {
                    correction_id: null,
                    user_id: null,
                    fio: null,
                };
                this.per_page = 20;
            },
            showModalMessage(text, classText) {
                this.message = {
                    text: text,
                    classText: classText,
                    show: true
                }
            },
            linkGen(pageNum) {
                return `/admin/corrections/${pageNum}`;
            },
            addCorrection() {
                this.message.show = false;
                this.$store.commit('prop/loading_spinner_show');
                this.$axios.post('/admin/corrections_add_correction', {
                    page: this.page,
                    per_page: this.per_page,
                    user_id: this.item_modal.user_id,
                    cost: this.item_modal.cost,
                    comment: this.item_modal.comment,
                })
                    .then((res) => {
                        if (res.data.status == 'success') {
                            this.item_modal = {
                                user_id: null,
                                cost: null,
                                comment: null,
                            };
                            this.items = res.data.items;
                            this.number_of_pages = res.data.number_of_pages;
                            this.showModalMessage(res.data.message, 'text-success');
                        } else if (res.data.status == 'bad_data') {
                            this.showModalMessage(res.data.message, 'text-danger');
                        }
                        this.$store.commit('prop/loading_spinner_hide');
                    })
                    .catch((error) => {
                        // eslint-disable-next-line
                        console.log(error);
                        this.$store.commit('prop/loading_spinner_hide');
                    });
            },
            showEditModal() {
                this.message.show = false;
                this.$refs['modalItem'].show();
            },
            hideEditModal() {
                this.$refs['modalItem'].hide();
            },
        },
        watch: {
            '$route'() {
                this.loadData();
            },
        },
        created() {
            this.loadData();
        }
    }
</script>

<style scoped>

</style>