<template>
    <div class="m-2">
        IndexManager
    </div>
</template>

<script>
    export default {
        name: "IndexManager"
    }
</script>

<style scoped>

</style>