import Vue from 'vue'
import Router from 'vue-router'
import Login from './components/Login'
import IndexUser from './components/index_page/IndexUser'
import IndexManager from './components/index_page/IndexManager'
import IndexAdmin from './components/index_page/IndexAdmin'
import Page_403 from './components/errors/Page_403'
import Page_423 from './components/errors/Page_423'
import Page_412 from './components/errors/Page_412'
import ListSpecifications from './components/user/ListSpecifications'
import AddSpecification from './components/user/AddSpecification'
import News from './components/user/News'
import Prizes from './components/user/Prizes'
import Balance from './components/user/Balance'
import MListSpecifications from './components/manager/MListSpecifications'
import MUsers from './components/manager/MUsers'
import ACorrections from './components/admin/ACorrections'
import APrizeApplications from './components/admin/APrizeApplications'
import AUsers from './components/admin/AUsers'
import AListSpecifications from './components/admin/AListSpecifications'
import ARegions from './components/admin/ARegions'
import APrizes from './components/admin/APrizes'
import ANews from './components/admin/ANews'
import ACompany from './components/admin/ACompany'
import AOfficialPosition from './components/admin/AOfficialPosition'
import ADownloadData from './components/admin/ADownloadData'


Vue.use(Router);

const routes = [
    {
        path: '/',
        component: Login
    },
    {
        path: '/index_user',
        component: IndexUser
    },
    {
        path: '/index_manager',
        component: IndexManager
    },
    {
        path: '/index_admin',
        component: IndexAdmin
    },
    {
        path: '/403',
        component: Page_403
    },
    {
        path: '/423',
        component: Page_423
    },
    {
        path: '/412',
        component: Page_412
    },
    {
        path: '/user/specifications/:page',
        component: ListSpecifications
    },
    {
        path: '/user/add_specification',
        component: AddSpecification
    },
    {
        path: '/user/prizes',
        component: Prizes
    },
    {
        path: '/user/news',
        component: News
    },
    {
        path: '/user/balance',
        component: Balance
    },
    {
        path: '/manager/specifications/:page',
        component: MListSpecifications
    },
    {
        path: '/manager/users',
        component: MUsers
    },
    {
        path: '/admin/corrections/:page',
        component: ACorrections
    },
    {
        path: '/admin/prize_applications/:page',
        component: APrizeApplications
    },
    {
        path: '/admin/users/:page',
        component: AUsers
    },
    {
        path: '/admin/specifications/:page',
        component: AListSpecifications
    },
    {
        path: '/admin/guide/regions',
        component: ARegions
    },
    {
        path: '/admin/guide/prizes',
        component: APrizes
    },
    {
        path: '/admin/guide/news/:page',
        component: ANews
    },
    {
        path: '/admin/guide/official_positions/:page',
        component: AOfficialPosition
    },
    {
        path: '/admin/guide/companies/:page',
        component: ACompany
    },
    {
        path: '/admin/guide/download_data',
        component: ADownloadData
    },

];


const router = new Router({
    routes: routes,
    // mode: 'abstract'
    // mode: 'history'
});


export default router;