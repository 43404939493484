import { render, staticRenderFns } from "./ARegions.vue?vue&type=template&id=5675e7e7&scoped=true&"
import script from "./ARegions.vue?vue&type=script&lang=js&"
export * from "./ARegions.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5675e7e7",
  null
  
)

export default component.exports