<template>
    <div id="app" class="m-2">
        <app_header/>
        <router-view/>
        <div id="page-preloader" v-show="loading_spinner">
            <circle10 size="100px" class="spinner"></circle10>
        </div>
    </div>
</template>

<script>
    import {Circle10} from 'vue-loading-spinner'
    import Header from './components/Header.vue'

    export default {
        name: 'App',
        components: {
            app_header: Header,
            Circle10
        },
        computed: {
            loading_spinner() {
                return this.$store.state.prop.loading_spinner;
            }
        },
        created() {
            this.$axios.interceptors.response.use(undefined,
                (error) => {
                    if (error.response.status === 401) {
                        // Очистка данных и редирект на форму login
                        this.$store.commit('auth/logout');
                        this.$router.push('/');
                    }
                    else if (error.response.status === 403) {
                        // Доступ запрещен (нет прав)
                        this.$router.push('/403');
                    }
                    else if (error.response.status === 423) {
                        // Пользователь заблокирован
                        this.$router.push('/423');
                    } else if (error.response.status === 412) {
                        // Пользователь заблокирован
                        this.$router.push('/412');
                    }
                    return Promise.reject(error.response.data);
                });
        }
    }
</script>

<style>
    #page-preloader {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: white;
        opacity: 0.7; /* Полупрозрачный фон */
        z-index: 100500;
    }

    #page-preloader .spinner {
        width: 100px;
        height: 100px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        opacity: 1; /* Полупрозрачный фон */
    }

    .flip-list-move {
        transition: transform 1s;
    }

    .flip-list-enter, .flip-list-leave-to {
        opacity: 0;
        /*transform: translateY(30px);*/
    }

    .flip-list-leave-active {
        position: absolute;
    }

    .strike {
        display: block;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
    }

    .strike > span {
        position: relative;
        display: inline-block;
    }

    .strike > span:before,
    .strike > span:after {
        content: " ";
        position: absolute;
        top: 50%;
        width: 9999px;
        height: 1px;
        background: gray;
    }

    .strike > span:before {
        right: 100%;
        margin-right: 15px;
    }

    .strike > span:after {
        left: 100%;
        margin-left: 15px;
    }
</style>
