import axios from 'axios'

let baseUrlBackend;
if (process.env.NODE_ENV === 'production') {
    baseUrlBackend = 'http://kflexproject.ru/backend';
} else {
    baseUrlBackend = 'http://localhost:5000';
}

const instance = axios.create({
    baseURL: baseUrlBackend,
    headers: {
        'Authorization': {
            toString() {
                return `Bearer ${localStorage.getItem('token') || ''}`
            }
        },
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
    },
});

export default instance