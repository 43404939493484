<template>
    <div class="m-3">
        <h3>История заявок</h3>

        <transition name="fade">
            <div class="m-1 border border-success" v-show="is_show_filter">
                <div class="row p-2">
                    <div class="col-4">
                        <b-form-group label="id Спецификации:"
                                      label-for="form-title-input">
                            <b-form-input type="number" min="1" step="1"
                                          v-model="filter.item_id">
                            </b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-4">
                        <b-form-group label="id Пользователя:"
                                      label-for="form-title-input">
                            <b-form-input type="number" min="1" step="1"
                                          v-model="filter.user_id">
                            </b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-4">
                        <b-form-group label="ФИО Пользователя"
                                      label-for="form-title-input">
                            <b-form-input type="text"
                                          v-model="filter.fio"
                                          maxlength="255">
                            </b-form-input>
                        </b-form-group>
                    </div>
                </div>
                <div class="row p-2">
                    <div class="col-3">
                        <b-form-group label="Кол-во на странице:"
                                      label-for="form-title-input">
                            <b-form-select v-model="per_page"
                                           :options="per_page_options"
                                           class="mb-3"
                                           value-field="value"
                                           text-field="text">
                            </b-form-select>
                        </b-form-group>
                    </div>
                    <div class="col-9 align-items-center">
                        <p class="text-right">
                            <b-button variant="secondary m-2" @click="cleanFilter">Очистить фильтр</b-button>
                            <b-button variant="secondary m-2" @click="setFilter">Применить фильтр</b-button>
                        </p>
                    </div>
                </div>
            </div>
        </transition>
        <div class="strike mb-1">
                    <span><a href="#"
                             @click="is_show_filter=!is_show_filter">настройки фильтра</a></span>
        </div>

        <b-table striped :items="items" :fields="fields" primary-key="id"
                 head-variant="dark"
                 table-variant="light"
                 :tbody-transition-props="{name:'flip-list'}"
                 :tbody-tr-class="rowClass"
        >
            <template #cell(create_dt)="data">
                {{ $moment(data.value).format('ll')}}
            </template>
            <template #cell(user_id)="data">
                {{ data.value}}
            </template>
            <template #cell(fio)="data">
                {{ user_by_id[data.item.user_id]}}
            </template>
            <template #cell(name)="{item}">
                {{ item.name }}
                <b-icon icon="window" variant="primary" @click="showEditModal(item)" style="cursor: pointer;">
                </b-icon>
            </template>
            <template #cell(status_id)="data">
                {{ status_by_id[data.value]['name']}}
            </template>
        </b-table>

        <b-modal :title="'Спецификация № ' + item_modal.id" ref="modalItem" size="xl"
                 no-close-on-backdrop no-close-on-esc hide-header-close hide-footer
                 body-bg-variant="light" header-bg-variant="warning">
            <b-form @submit.prevent="editModal">
                <p>
                    <b>Пользователь:</b> {{item_modal.user_id == null ? '' : user_by_id[item_modal.user_id].fio}}<br>
                    <b>Дата:</b> {{ $moment(item_modal.create_dt).format('ll')}}<br>
                    <b>Название обьекта:</b> {{item_modal.name}}<br>
                    <b>Генеральный проектировщик:</b> {{item_modal.gen_proekt}}<br>
                    <b>Адрес обьекта:</b> {{item_modal.address}}<br>
                    <b>Статус заявки:</b> {{item_modal.status_id in status_by_id ?
                    status_by_id[item_modal.status_id]['name'] : 'Нет'}}<br>
                    <template v-if="item_modal.status_id == 2">
                        <b>Баллы:</b> {{item_modal.value}}
                    </template>
                    <template v-else-if="item_modal.status_id == 1">
                        <b>Баллы:</b> Кол-во баллов появится после обработки заявки
                    </template>
                    <template v-else>
                        <b>Баллы:</b> Баллы не были начислены
                    </template>
                </p>
                <template v-if="item_modal.zakazchik_1 !== null">
                    <hr>
                    <p>
                        <b>Заказчик:</b> {{item_modal.zakazchik_1}}
                        <template v-if="item_modal.zakazchik_2 !== null">
                            <br><b>Контактное лицо:</b> {{item_modal.zakazchik_2}}
                        </template>
                        <template v-if="item_modal.zakazchik_3 !== null">
                            <br><b>Контактный телефон:</b> {{item_modal.zakazchik_3}}
                        </template>
                    </p>
                </template>
                <template v-if="item_modal.gen_podr_1 !== null">
                    <hr>
                    <p>
                        <b>Генеральный подрядчик:</b> {{item_modal.gen_podr_1}}
                        <template v-if="item_modal.gen_podr_2 !== null">
                            <br><b>Контактное лицо:</b> {{item_modal.gen_podr_2}}
                        </template>
                        <template v-if="item_modal.gen_podr_3 !== null">
                            <br><b>Контактный телефон:</b> {{item_modal.gen_podr_3}}
                        </template>
                    </p>
                </template>
                <template v-if="item_modal.podr_1 !== null">
                    <hr>
                    <p>
                        <b>Подрядчик:</b> {{item_modal.podr_1}}
                        <template v-if="item_modal.podr_2 !== null">
                            <br><b>Контактное лицо:</b> {{item_modal.podr_2}}
                        </template>
                        <template v-if="item_modal.podr_2 !== null">
                            <br><b>Контактный телефон:</b> {{item_modal.podr_2}}
                        </template>
                    </p>
                </template>
                <template v-if="item_modal.sub_podr_1 !== null">
                    <hr>
                    <p>
                        <b>Субподрядчик:</b> {{item_modal.sub_podr_1}}
                        <template v-if="item_modal.sub_podr_2 !== null">
                            <br><b>Контактное лицо:</b> {{item_modal.sub_podr_2}}
                        </template>
                        <template v-if="item_modal.sub_podr_3 !== null">
                            <br><b>Контактный телефон:</b> {{item_modal.sub_podr_3}}
                        </template>
                    </p>
                </template>
                <template v-if="item_modal.postavshik !== null">
                    <hr>
                    <p>
                        <b>Поставщик:</b> {{item_modal.postavshik}}
                    </p>
                </template>
                <hr>
                <div class="row m-2">
                    <div class="col-3">
                        <template v-if="item_modal.status_id == 1">
                            <b-form-group label="Нач. баллы"
                                          label-for="form-title-input">
                                <b-form-input type="number" min="0" step="1"
                                              v-model="item_modal.start_value"
                                              required
                                              placeholder="Нач. баллы">
                                </b-form-input>
                            </b-form-group>
                        </template>
                        <template v-else>
                            <b-form-group label="Нач. баллы">
                                    <span class="ml-2 font-weight-bold">
                                        {{ item_modal.start_value }}
                                    </span>
                            </b-form-group>
                        </template>
                    </div>
                    <div class="col-3">
                        <b-form-group label="Нач. коэф">
                                    <span class="ml-2 font-weight-bold">
                                        {{ item_modal.start_coef }}
                                    </span>
                        </b-form-group>
                    </div>
                    <div class="col-3">
                        <template v-if="item_modal.status_id == 1">
                            <b-form-group label="Коэф."
                                          label-for="form-title-input">
                                <b-form-input type="number" min="0" step="1"
                                              v-model="item_modal.coef"
                                              required
                                              placeholder="Коэф">
                                </b-form-input>
                            </b-form-group>
                        </template>
                        <template v-else>
                            <b-form-group label="Коэф.">
                                    <span class="ml-2 font-weight-bold">
                                        {{ item_modal.coef }}
                                    </span>
                            </b-form-group>
                        </template>
                    </div>
                    <div class="col-3">
                        <b-form-group label="Баллы">
                                    <span class="ml-2 font-weight-bold">
                                        {{ item_modal.value }}
                                    </span>
                        </b-form-group>
                    </div>
                </div>

                <template v-show="item_modal.file_data.length > 0">
                    <hr>
                    <div class="row">
                        <div class="col">
                        <span class="m-2 font-weight-bold">
                                Документы
                        </span>
                            <ul v-for="el in item_modal.file_data" :key="el.text">
                                <li>
                                    <span>{{ el.text }}</span>
                                    <a href="#" @click="getFileDoc(el.text)" class="m-1">скачать</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </template>
                <transition enter-active-class="animated flipInX"
                            leave-active-class="animated flipOutX">
                    <div v-show="message.show" class="mb-2">
                            <span :class="message.classText">
                            {{message.text}}
                            </span>
                    </div>
                </transition>
                <div class="modal-footer m-2">
                    <template v-if="item_modal.status_id == 1">
                        <span class="font-weight-bold">Выберите новый статус</span>
                        <b-form-select v-model="new_status_id"
                                       :options="status_options"
                                       value-field="id"
                                       text-field="name"
                                       required>
                        </b-form-select>
                        <b-button variant="success" type="submit">Сохранить</b-button>
                    </template>
                    <b-button variant="secondary" @click="hideEditModal">Закрыть</b-button>
                </div>
            </b-form>
        </b-modal>

        <div class="row mt-3 ml-3">
            <div class="col">
                <b-pagination-nav
                        :link-gen="linkGen"
                        :number-of-pages="number_of_pages"
                        use-router
                        align="right">
                </b-pagination-nav>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AListSpecifications",
        data() {
            return {
                items: [],
                page: null,
                per_page: 20,
                number_of_pages: 1,
                new_status_id: null,
                fields: [
                    {
                        label: "id",
                        key: 'id',
                        sortable: true
                    },
                    {
                        label: "Дата",
                        key: 'create_dt',
                        sortable: true
                    },
                    {
                        label: "Пользовать id",
                        key: 'user_id',
                        sortable: true
                    },
                    {
                        label: "ФИО",
                        key: 'fio', sortable: true,
                        sortByFormatted: true,
                        formatter: (value, key, item) => {
                            return this.user_by_id[item.user_id];
                        },
                    },
                    {
                        label: "Название",
                        key: 'name',
                        sortable: true
                    },
                    {
                        label: "Баллы",
                        key: 'value',
                        sortable: true
                    },
                    {
                        label: "Статус",
                        key: 'status_id',
                        sortable: true
                    },
                ],
                status_by_id: {},
                status_options: [],
                user_by_id: {},
                item_modal: {
                    id: null,
                    user_id: null,
                    gen_proekt: null,
                    name: null,
                    address: null,
                    start_value: null,
                    start_coef: null,
                    create_dt: null,
                    modified_dt: null,
                    value: null,
                    coef: null,
                    zakazchik_1: null,
                    zakazchik_2: null,
                    zakazchik_3: null,
                    gen_podr_1: null,
                    gen_podr_2: null,
                    gen_podr_3: null,
                    podr_1: null,
                    podr_2: null,
                    podr_3: null,
                    sub_podr_1: null,
                    sub_podr_2: null,
                    sub_podr_3: null,
                    postavshik: null,
                    status_id: null,
                    file_data: []
                },
                message: {
                    text: null,
                    show: false,
                    classText: null
                },
                is_show_filter: false,
                filter: {
                    item_id: null,
                    user_id: null,
                    fio: null,
                },
                per_page_options: [
                    {text: 20, value: 20},
                    {text: 40, value: 40},
                    {text: 60, value: 60},
                    {text: 80, value: 80},
                    {text: 100, value: 100},
                ],
            }
        },
        methods: {
            loadData() {
                this.$store.commit('prop/loading_spinner_show');
                this.page = this.$route.params.page;
                this.$axios.post('/admin/specifications_data_for_page', {
                    page: this.page,
                    per_page: this.per_page,
                    f_item_id: this.filter.item_id,
                    f_user_id: this.filter.user_id,
                    f_fio: this.filter.fio,
                })
                    .then((res) => {
                        this.items = res.data.items;
                        this.number_of_pages = res.data.number_of_pages;
                        this.status_by_id = res.data.status_by_id;
                        this.user_by_id = res.data.user_by_id;
                        this.status_options = res.data.status_options;
                        this.$store.commit('prop/loading_spinner_hide');
                    })
                    .catch((error) => {
                        // eslint-disable-next-line
                        console.log(error);
                        this.$store.commit('prop/loading_spinner_hide');
                    });
            },
            showModalMessage(text, classText) {
                this.message = {
                    text: text,
                    classText: classText,
                    show: true
                }
            },
            editModal() {
                this.message.show = false;
                if (this.new_status_id == 1 || this.new_status_id == null) {
                    this.showModalMessage('Нужно выбрать другой статус', 'text-danger');
                    return
                }

                this.$store.commit('prop/loading_spinner_show');
                this.$axios.post('/admin/specifications_edit_item', {
                    'sp_id': this.item_modal.id,
                    'start_value': this.item_modal.start_value,
                    'coef': this.item_modal.coef,
                    'new_status_id': this.new_status_id,
                })
                    .then((res) => {
                        if (res.data.status == 'success') {
                            this.item_modal.status_id = this.new_status_id;
                            for (let index = 0; index < this.items.length; ++index) {
                                if (this.items[index].id === this.item_modal.id) {
                                    this.$set(this.items, index, this.item_modal)
                                }
                            }
                            this.showModalMessage(res.data.message, 'text-success');
                        } else if (res.data.status == 'bad_data') {
                            this.showModalMessage(res.data.message, 'text-danger');
                        }
                        this.$store.commit('prop/loading_spinner_hide');
                    })
                    .catch((error) => {
                        // eslint-disable-next-line
                        console.log(error);
                        this.$store.commit('prop/loading_spinner_hide');
                    });
            },
            linkGen(pageNum) {
                return `/admin/specifications/${pageNum}`;
            },
            rowClass(item) {
                if (item['status_id'] === 2) {
                    return 'table-warning'
                } else if (item['status_id'] === 3) {
                    return 'table-danger'
                }
                else if (item['status_id'] === 4) {
                    return 'table-success'
                }
            },
            showEditModal(item) {
                this.item_modal = JSON.parse(JSON.stringify(item));
                this.$refs['modalItem'].show();
            },
            hideEditModal() {
                this.$refs['modalItem'].hide();
            },
            getFileDoc(filename) {
                this.$axios.get('/files/specification/' + this.item_modal.id + '/' + filename + '?' + Math.random(),
                    {responseType: 'blob'})
                    .then((response) => {
                        this.$fileDownload(response.data, filename);
                    });
            },
            setFilter() {
                if (this.page == 1) {
                    this.loadData();
                } else {
                    this.$router.push('/admin/specification/1');
                }
            },
            cleanFilter() {
                this.filter = {
                    item_id: null,
                    user_id: null,
                    fio: null,
                };
                this.per_page = 20;
            },
        },
        watch: {
            '$route'() {
                this.loadData();
            },
            'item_modal.coef': function (val) {
                this.item_modal.value = Math.ceil(this.item_modal.start_value * (1 + val / 100));
            },
            'item_modal.start_value': function (val) {
                this.item_modal.value = Math.ceil(val * (1 + this.item_modal.coef / 100));
            }
        },
        created() {
            this.loadData();
        }
    }
</script>

<style scoped>

</style>