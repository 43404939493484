<template>
    <div class="m-3">
        <h3>История заявок</h3>

        <transition name="fade">
            <div class="m-1 border border-success" v-show="filter.show">
                <div class="row m-1 align-items-center">
                    <div class="col-6">
                        <b-form-group label="Пользователь:"
                                      label-for="form-title-input">
                            <b-form-select v-model="filter.user_id"
                                           :options="user_options"
                                           class="mb-3"
                                           value-field="id"
                                           text-field="fio">
                            </b-form-select>
                        </b-form-group>
                    </div>
                    <div class="col-6">
                        <p class="text-center">
                            <b-button variant="secondary m-2" @click="filter.user_id=null">Очистить фильтр</b-button>
                            <b-button variant="secondary m-2" @click="setFilter">Применить фильтр</b-button>
                        </p>
                    </div>
                </div>
            </div>
        </transition>
        <div class="strike mb-1">
                    <span><a href="#"
                             @click="filter.show=!filter.show">настройки фильтра</a></span>
        </div>

        <b-table striped :items="items" :fields="fields" primary-key="id"
                 head-variant="dark"
                 table-variant="light"
                 :tbody-transition-props="{name:'flip-list'}"
                 :tbody-tr-class="rowClass"
        >
            <template #cell(create_dt)="data">
                {{ $moment(data.value).format('ll')}}
            </template>
            <template #cell(user_id)="data">
                {{ user_by_id[data.value]['fio']}}
            </template>
            <template #cell(name)="{item}">
                {{ item.name }}
                <b-icon icon="window" variant="primary" @click="showEditModal(item)" style="cursor: pointer;">
                </b-icon>
            </template>
            <template #cell(status_id)="data">
                {{ status_by_id[data.value]['name']}}
            </template>
        </b-table>

        <b-modal :title="'Спецификация № ' + item_modal.id" ref="modalItem" size="xl"
                 no-close-on-backdrop no-close-on-esc hide-header-close hide-footer
                 body-bg-variant="light" header-bg-variant="warning">
            <p>
                <b>Пользователь:</b> {{item_modal.user_id == null ? '' : user_by_id[item_modal.user_id].fio}}<br>
                <b>Дата:</b> {{ $moment(item_modal.create_dt).format('ll')}}<br>
                <b>Название обьекта:</b> {{item_modal.name}}<br>
                <b>Генеральный проектировщик:</b> {{item_modal.gen_proekt}}<br>
                <b>Адрес обьекта:</b> {{item_modal.address}}<br>
                <b>Статус заявки:</b> {{item_modal.status_id in status_by_id ?
                status_by_id[item_modal.status_id]['name'] : 'Нет'}}<br>
                <template v-if="item_modal.status_id == 2">
                    <b>Баллы:</b> {{item_modal.value}}
                </template>
                <template v-else-if="item_modal.status_id == 1">
                    <b>Баллы:</b> Кол-во баллов появится после обработки заявки
                </template>
                <template v-else>
                    <b>Баллы:</b> Баллы не были начислены
                </template>
            </p>
            <template v-if="item_modal.zakazchik_1 !== null">
                <hr>
                <p>
                    <b>Заказчик:</b> {{item_modal.zakazchik_1}}
                    <template v-if="item_modal.zakazchik_2 !== null">
                        <br><b>Контактное лицо:</b> {{item_modal.zakazchik_2}}
                    </template>
                    <template v-if="item_modal.zakazchik_3 !== null">
                        <br><b>Контактный телефон:</b> {{item_modal.zakazchik_3}}
                    </template>
                </p>
            </template>
            <template v-if="item_modal.gen_podr_1 !== null">
                <hr>
                <p>
                    <b>Генеральный подрядчик:</b> {{item_modal.gen_podr_1}}
                    <template v-if="item_modal.gen_podr_2 !== null">
                        <br><b>Контактное лицо:</b> {{item_modal.gen_podr_2}}
                    </template>
                    <template v-if="item_modal.gen_podr_3 !== null">
                        <br><b>Контактный телефон:</b> {{item_modal.gen_podr_3}}
                    </template>
                </p>
            </template>
            <template v-if="item_modal.podr_1 !== null">
                <hr>
                <p>
                    <b>Подрядчик:</b> {{item_modal.podr_1}}
                    <template v-if="item_modal.podr_2 !== null">
                        <br><b>Контактное лицо:</b> {{item_modal.podr_2}}
                    </template>
                    <template v-if="item_modal.podr_2 !== null">
                        <br><b>Контактный телефон:</b> {{item_modal.podr_2}}
                    </template>
                </p>
            </template>
            <template v-if="item_modal.sub_podr_1 !== null">
                <hr>
                <p>
                    <b>Субподрядчик:</b> {{item_modal.sub_podr_1}}
                    <template v-if="item_modal.sub_podr_2 !== null">
                        <br><b>Контактное лицо:</b> {{item_modal.sub_podr_2}}
                    </template>
                    <template v-if="item_modal.sub_podr_3 !== null">
                        <br><b>Контактный телефон:</b> {{item_modal.sub_podr_3}}
                    </template>
                </p>
            </template>
            <template v-if="item_modal.postavshik !== null">
                <hr>
                <p>
                    <b>Поставщик:</b> {{item_modal.postavshik}}
                </p>
            </template>

            <template v-show="item_modal.file_data.length > 0">
                <hr>
                <div class="row">
                    <div class="col">
                        <span class="m-2 font-weight-bold">
                                Документы
                        </span>
                        <ul v-for="el in item_modal.file_data" :key="el.text">
                            <li>
                                <span>{{ el.text }}</span>
                                <a href="#" @click="getFileDoc(el.text)" class="m-1">скачать</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </template>

            <div class="modal-footer m-2">
                <b-button variant="secondary" @click="hideEditModal">Закрыть</b-button>
            </div>
        </b-modal>

        <div class="row mt-3 ml-3">
            <div class="col">
                <b-pagination-nav
                        :link-gen="linkGen"
                        :number-of-pages="number_of_pages"
                        use-router
                        align="right">
                </b-pagination-nav>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "MListSpecifications",
        data() {
            return {
                items: [],
                page: null,
                per_page: 20,
                number_of_pages: 1,
                filter: {
                    show: false,
                    user_id: null,
                },
                fields: [
                    {
                        label: "Дата",
                        key: 'create_dt',
                        sortable: true
                    },
                    {
                        label: "Пользователь",
                        key: 'user_id',
                        sortable: true
                    },
                    {
                        label: "Название",
                        key: 'name',
                        sortable: true
                    },
                    {
                        label: "Баллы",
                        key: 'value',
                        sortable: true
                    },
                    {
                        label: "Статус",
                        key: 'status_id',
                        sortable: true
                    },
                ],
                status_by_id: {},
                item_modal: {
                    id: null,
                    user_id: null,
                    gen_proekt: null,
                    name: null,
                    address: null,
                    start_value: null,
                    start_coef: null,
                    create_dt: null,
                    modified_dt: null,
                    value: null,
                    coef: null,
                    zakazchik_1: null,
                    zakazchik_2: null,
                    zakazchik_3: null,
                    gen_podr_1: null,
                    gen_podr_2: null,
                    gen_podr_3: null,
                    podr_1: null,
                    podr_2: null,
                    podr_3: null,
                    sub_podr_1: null,
                    sub_podr_2: null,
                    sub_podr_3: null,
                    postavshik: null,
                    status_id: null,
                    file_data: []
                },
                user_by_id: {},
                user_options: [],
            }
        },
        methods: {
            loadData() {
                this.$store.commit('prop/loading_spinner_show');
                this.page = this.$route.params.page;
                this.$axios.post('/manager/specifications_data_for_page', {
                    page: this.page,
                    per_page: this.per_page,
                    f_user_id: this.filter.user_id,
                })
                    .then((res) => {
                        this.items = res.data.items;
                        this.number_of_pages = res.data.number_of_pages;
                        this.status_by_id = res.data.status_by_id;
                        this.user_by_id = res.data.user_by_id;
                        this.user_options = res.data.user_options;
                        this.$store.commit('prop/loading_spinner_hide');
                    })
                    .catch((error) => {
                        // eslint-disable-next-line
                        console.log(error);
                        this.$store.commit('prop/loading_spinner_hide');
                    });
            },
            setFilter(){
                if (this.page == 1) {
                    this.loadData();
                } else {
                    this.$router.push('/manager/specifications/1');
                }
            },
            linkGen(pageNum) {
                return `/manager/specifications/${pageNum}`;
            },
            rowClass(item) {
                if (item['status_id'] === 2) {
                    return 'table-warning'
                } else if (item['status_id'] === 3) {
                    return 'table-danger'
                }
                else if (item['status_id'] === 4) {
                    return 'table-success'
                }
            },
            showEditModal(item) {
                this.item_modal = JSON.parse(JSON.stringify(item));
                this.$refs['modalItem'].show();
            },
            hideEditModal() {
                this.$refs['modalItem'].hide();
            },
            getFileDoc(filename) {
                this.$axios.get('/files/specification/' + this.item_modal.id + '/' + filename + '?' + Math.random(),
                    {responseType: 'blob'})
                    .then((response) => {
                        this.$fileDownload(response.data, filename);
                    });
            },
        },
        watch: {
            '$route'() {
                this.loadData();
            },
        },
        created() {
            this.loadData();
        }
    }
</script>

<style scoped>

</style>