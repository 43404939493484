<template>
    <div class="m-3">
        <div class="row m-2 d-flex justify-content-start">
            <div class="col-9 d-inline-flex">
                <h3>Должности</h3>
            </div>
            <div class="col-3 text-right d-inline-flex justify-content-end">
                <b-button variant="outline-secondary" @click="showAddModal">Добавить должность</b-button>
            </div>
        </div>

        <transition name="fade">
            <div class="m-1 border border-success" v-show="is_show_filter">
                <div class="row p-2">
                    <div class="col-6">
                        <b-form-group label="id Должности:"
                                      label-for="form-title-input">
                            <b-form-input type="number" min="1" step="1"
                                          v-model="filter.item_id">
                            </b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-6">
                        <b-form-group label="Название должности"
                                      label-for="form-title-input">
                            <b-form-input type="text"
                                          v-model="filter.item_name"
                                          maxlength="255">
                            </b-form-input>
                        </b-form-group>
                    </div>
                </div>
                <div class="row p-2">
                    <div class="col-3">
                        <b-form-group label="Кол-во на странице:"
                                      label-for="form-title-input">
                            <b-form-select v-model="per_page"
                                           :options="per_page_options"
                                           class="mb-3"
                                           value-field="value"
                                           text-field="text">
                            </b-form-select>
                        </b-form-group>
                    </div>
                    <div class="col-9 align-items-center">
                        <p class="text-right">
                            <b-button variant="secondary m-2" @click="cleanFilter">Очистить фильтр</b-button>
                            <b-button variant="secondary m-2" @click="setFilter">Применить фильтр</b-button>
                        </p>
                    </div>
                </div>
            </div>
        </transition>
        <div class="strike mb-1">
            <span><a href="#" @click="is_show_filter=!is_show_filter">настройки фильтра</a></span>
        </div>

        <b-table striped :items="items" :fields="fields" primary-key="id"
                 head-variant="dark"
                 table-variant="light"
                 :tbody-transition-props="{name:'flip-list'}"
        >
            <template #cell(id)="{item}">
                {{ item.id }}
                <b-icon icon="window" variant="primary" @click="showEditModal(item)" style="cursor: pointer;">
                </b-icon>
            </template>
        </b-table>

        <div class="row mt-3 ml-3">
            <div class="col">
                <b-pagination-nav
                        :link-gen="linkGen"
                        :number-of-pages="number_of_pages"
                        use-router
                        align="right">
                </b-pagination-nav>
            </div>
        </div>

        <b-modal :title="item_modal.id != null ?'Редактировать должность - ' + item_modal.id : 'Добавить должность'"
                 ref="modalItem"
                 no-close-on-backdrop no-close-on-esc hide-header-close hide-footer
                 body-bg-variant="light" header-bg-variant="warning">
            <b-form @submit.prevent="submitModal">
                <div class="row m-2">
                    <div class="col">
                        <b-form-group label="Название должности"
                                      label-for="form-title-input">
                            <b-form-input type="text"
                                          v-model="item_modal.name"
                                          maxlength="255">
                            </b-form-input>
                        </b-form-group>
                    </div>
                </div>

                <transition enter-active-class="animated flipInX"
                            leave-active-class="animated flipOutX">
                    <div v-show="message.show" class="mb-2">
                            <span :class="message.classText">
                            {{message.text}}
                            </span>
                    </div>
                </transition>
                <div class="modal-footer m-2">
                    <b-button variant="success" type="submit">{{ item_modal.id != null ?'Редактировать' : 'Добавить'}}
                    </b-button>
                    <b-button variant="secondary" @click="hideEditModal">Закрыть</b-button>
                </div>
            </b-form>
        </b-modal>
    </div>
</template>

<script>
    export default {
        name: "AOfficialPosition",
        data() {
            return {
                page: null,
                per_page: 20,
                number_of_pages: 1,
                items: [],
                is_show_filter: false,
                filter: {
                    item_id: null,
                    item_name: null,
                },
                fields: [
                    {
                        label: "№",
                        key: 'id',
                        sortable: true
                    },
                    {
                        label: "Название",
                        key: 'name',
                        sortable: true
                    },
                ],
                message: {
                    text: null,
                    show: false,
                    classText: null
                },
                item_modal: {
                    id: null,
                    name: null,
                },
                per_page_options: [
                    {text: 20, value: 20},
                    {text: 40, value: 40},
                    {text: 60, value: 60},
                    {text: 80, value: 80},
                    {text: 100, value: 100},
                ],
            }
        },
        methods: {
            loadData() {
                this.$store.commit('prop/loading_spinner_show');
                this.page = this.$route.params.page;
                this.$axios.post('/admin/official_positions_data_for_page', {
                    page: this.page,
                    per_page: this.per_page,
                    f_item_id: this.filter.item_id,
                    f_item_name: this.filter.item_name,
                })
                    .then((res) => {
                        this.items = res.data.items;
                        this.number_of_pages = res.data.number_of_pages;
                        this.$store.commit('prop/loading_spinner_hide');
                    })
                    .catch((error) => {
                        // eslint-disable-next-line
                        console.log(error);
                        this.$store.commit('prop/loading_spinner_hide');
                    });
            },
            showModalMessage(text, classText) {
                this.message = {
                    text: text,
                    classText: classText,
                    show: true
                }
            },
            cleanFilter() {
                this.filter = {
                    item_id: null,
                    item_name: null,
                }
            },
            setFilter() {
                if (this.page == 1) {
                    this.loadData();
                } else {
                    this.$router.push('/admin/guide/official_positions/1');
                }
            },
            linkGen(pageNum) {
                return `/admin/guide/official_positions/${pageNum}`;
            },
            showEditModal(item) {
                this.item_modal = JSON.parse(JSON.stringify(item));
                this.message.show = false;
                this.$refs['modalItem'].show();
            },
            showAddModal() {
                this.message.show = false;
                this.item_modal = {
                    id: null,
                    name: null,
                };
                this.$refs['modalItem'].show();
            },
            hideEditModal() {
                this.$refs['modalItem'].hide();
            },
            submitModal() {
                if (this.item_modal.id == null) {
                    this.addItem();
                } else {
                    this.editItem();
                }
            },
            editItem() {
                this.$store.commit('prop/loading_spinner_show');
                this.page = this.$route.params.page;
                this.$axios.post('/admin/official_positions_edit_item', {
                    page: this.page,
                    per_page: this.per_page,
                    f_item_id: this.filter.item_id,
                    f_item_name: this.filter.item_name,
                    item_id: this.item_modal.id,
                    item_name: this.item_modal.name,
                })
                    .then((res) => {
                        if (res.data.status == 'success') {
                            this.items = res.data.items;
                            this.number_of_pages = res.data.number_of_pages;
                            this.showModalMessage(res.data.message, 'text-success');
                        } else if (res.data.status == 'bad_data') {
                            this.showModalMessage(res.data.message, 'text-danger');
                        }
                        this.$store.commit('prop/loading_spinner_hide');
                    })
                    .catch((error) => {
                        // eslint-disable-next-line
                        console.log(error);
                        this.$store.commit('prop/loading_spinner_hide');
                    });
            },
            addItem() {
                this.$store.commit('prop/loading_spinner_show');
                this.page = this.$route.params.page;
                this.$axios.post('/admin/official_positions_add_item', {
                    page: this.page,
                    per_page: this.per_page,
                    f_item_id: this.filter.item_id,
                    f_item_name: this.filter.item_name,
                    item_name: this.item_modal.name,
                })
                    .then((res) => {
                        if (res.data.status == 'success') {
                            this.items = res.data.items;
                            this.number_of_pages = res.data.number_of_pages;
                            this.item_modal.name = null;
                            this.showModalMessage(res.data.message, 'text-success');
                        } else if (res.data.status == 'bad_data') {
                            this.showModalMessage(res.data.message, 'text-danger');
                        }
                        this.$store.commit('prop/loading_spinner_hide');
                    })
                    .catch((error) => {
                        // eslint-disable-next-line
                        console.log(error);
                        this.$store.commit('prop/loading_spinner_hide');
                    });
            },
        },
        watch: {
            '$route'() {
                this.loadData();
            },
        },
        created() {
            this.loadData();
        }
    }
</script>

<style scoped>

</style>