<template>
    <div class="m-3">
        <h3>Отправить заявку</h3>
        <b-form @submit.prevent="submitAddSpecification">
            <div class="row">
                <div class="col-4">
                    <b-form-group label="Генеральный проектировщик*">
                        <b-form-input type="text"
                                      v-model="item.gen_proekt"
                                      maxlength="255"
                                      required
                                      placeholder="Генеральный проектировщик">
                        </b-form-input>
                    </b-form-group>
                </div>
                <div class="col-4">
                    <b-form-group label="Название обьекта*">
                        <b-form-input type="text"
                                      v-model="item.name"
                                      maxlength="255"
                                      required
                                      placeholder="Название обьекта">
                        </b-form-input>
                    </b-form-group>
                </div>
                <div class="col-4">
                    <b-form-group label="Адрес обьекта*">
                        <b-form-input type="text"
                                      v-model="item.address"
                                      maxlength="255"
                                      required
                                      placeholder="Адрес обьекта">
                        </b-form-input>
                    </b-form-group>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <b-form-group label="Добавить файлы*"
                                  description="Не более 10Мб">
                        <b-form-file
                                v-model="add_files"
                                placeholder="Можно выбрать несколько файлов"
                                required
                                multiple
                        ></b-form-file>
                    </b-form-group>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-4">
                    <b-form-group label="Заказчик">
                        <b-form-input type="text"
                                      v-model="item.zakazchik_1"
                                      maxlength="255"
                                      placeholder="Заказчик">
                        </b-form-input>
                        <b-form-text id="input-live-help">1 балл</b-form-text>
                    </b-form-group>
                </div>
                <div class="col-8">
                    <transition name="fade">
                        <div class="row" v-if="item.zakazchik_1 != null && item.zakazchik_1.length > 0">
                            <div class="col-6">
                                <b-form-group label="Заказчик контактное лицо">
                                    <b-form-input type="text"
                                                  v-model="item.zakazchik_2"
                                                  maxlength="255"
                                                  placeholder="Контактное лицо">
                                    </b-form-input>
                                    <b-form-text id="input-live-help">1 балл</b-form-text>
                                </b-form-group>
                            </div>
                            <div class="col-6">
                                <b-form-group label="Заказчик контактный телефон">
                                    <b-form-input type="text"
                                                  v-model="item.zakazchik_3"
                                                  maxlength="255"
                                                  placeholder="Контактный телефон">
                                    </b-form-input>
                                    <b-form-text id="input-live-help">1 балл</b-form-text>
                                </b-form-group>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <b-form-group label="Генеральный подрядчик">
                        <b-form-input type="text"
                                      v-model="item.gen_podr_1"
                                      maxlength="255"
                                      placeholder="Генеральный подрядчик">
                        </b-form-input>
                        <b-form-text id="input-live-help">2 балла</b-form-text>
                    </b-form-group>
                </div>
                <div class="col-8">
                    <transition name="fade">
                        <div class="row" v-if="item.gen_podr_1 != null && item.gen_podr_1.length > 0">
                            <div class="col-6">
                                <b-form-group label="Генеральный подрядчик контактное лицо">
                                    <b-form-input type="text"
                                                  v-model="item.gen_podr_2"
                                                  maxlength="255"
                                                  placeholder="Контактное лицо">
                                    </b-form-input>
                                    <b-form-text id="input-live-help">2 балла</b-form-text>
                                </b-form-group>
                            </div>
                            <div class="col-6">
                                <b-form-group label="Генеральный подрядчик контактный телефон">
                                    <b-form-input type="text"
                                                  v-model="item.gen_podr_3"
                                                  maxlength="255"
                                                  placeholder="Контактный телефон">
                                    </b-form-input>
                                    <b-form-text id="input-live-help">2 балла</b-form-text>
                                </b-form-group>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <b-form-group label="Подрядчик">
                        <b-form-input type="text"
                                      v-model="item.podr_1"
                                      maxlength="255"
                                      placeholder="Подрядчик">
                        </b-form-input>
                        <b-form-text id="input-live-help">3 балла</b-form-text>
                    </b-form-group>
                </div>
                <div class="col-8">
                    <transition name="fade">
                        <div class="row" v-if="item.podr_1 != null && item.podr_1.length > 0">
                            <div class="col-6">
                                <b-form-group label="Подрядчик контактное лицо">
                                    <b-form-input type="text"
                                                  v-model="item.podr_2"
                                                  maxlength="255"
                                                  placeholder="Контактное лицо">
                                    </b-form-input>
                                    <b-form-text id="input-live-help">3 балла</b-form-text>
                                </b-form-group>
                            </div>
                            <div class="col-6">
                                <b-form-group label="Подрядчик контактный телефон">
                                    <b-form-input type="text"
                                                  v-model="item.podr_3"
                                                  maxlength="255"
                                                  placeholder="Контактный телефон">
                                    </b-form-input>
                                    <b-form-text id="input-live-help">3 балла</b-form-text>
                                </b-form-group>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <b-form-group label="Субподрядчик">
                        <b-form-input type="text"
                                      v-model="item.sub_podr_1"
                                      maxlength="255"
                                      placeholder="Субподрядчик">
                        </b-form-input>
                        <b-form-text id="input-live-help">3 балла</b-form-text>
                    </b-form-group>
                </div>
                <div class="col-8">
                    <transition name="fade">
                        <div class="row" v-if="item.sub_podr_1 != null && item.sub_podr_1.length > 0">
                            <div class="col-6">
                                <b-form-group label="Субподрядчик контактное лицо">
                                    <b-form-input type="text"
                                                  v-model="item.sub_podr_2"
                                                  maxlength="255"
                                                  placeholder="Контактное лицо">
                                    </b-form-input>
                                    <b-form-text id="input-live-help">4 балла</b-form-text>
                                </b-form-group>
                            </div>
                            <div class="col-6">
                                <b-form-group label="Субподрядчик контактный телефон">
                                    <b-form-input type="text"
                                                  v-model="item.sub_podr_3"
                                                  maxlength="255"
                                                  placeholder="Контактный телефон">
                                    </b-form-input>
                                    <b-form-text id="input-live-help">4 балла</b-form-text>
                                </b-form-group>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
            <div class="row">
                <div class="col-4">
                    <b-form-group label="Поставщик">
                        <b-form-input type="text"
                                      v-model="item.postavshik"
                                      maxlength="255"
                                      placeholder="Поставщик">
                        </b-form-input>
                        <b-form-text id="input-live-help">1 балл</b-form-text>
                    </b-form-group>
                </div>
                <div class="col-4"></div>
                <div class="col-4"></div>
            </div>
            <transition enter-active-class="animated flipInX"
                        leave-active-class="animated flipOutX">
                <div v-show="message.show" class="mb-2">
                            <span :class="message.classText">
                            {{message.text}}
                            </span>
                </div>
            </transition>
            <div class="modal-footer m-2">
                <b-button type="submit" variant="primary">Отправить</b-button>
            </div>
        </b-form>
    </div>
</template>

<script>
    export default {
        name: "AddSpecification",
        data() {
            return {
                item: {
                    gen_proekt: null,
                    name: null,
                    address: null,
                    zakazchik_1: null,
                    zakazchik_2: null,
                    zakazchik_3: null,
                    gen_podr_1: null,
                    gen_podr_2: null,
                    gen_podr_3: null,
                    podr_1: null,
                    podr_2: null,
                    podr_3: null,
                    sub_podr_1: null,
                    sub_podr_2: null,
                    sub_podr_3: null,
                    postavshik: null,
                },
                add_files: [],
                message: {
                    text: null,
                    show: false,
                    classText: null
                },
            }
        },
        methods: {
            submitAddSpecification() {
                this.message.show = false;
                let formData = new FormData();
                formData.append('item', JSON.stringify(this.item));
                let i = 1;
                this.add_files.forEach(function (file) {
                    formData.append('file' + i, file);
                    i += 1;
                });

                this.$store.commit('prop/loading_spinner_show');
                this.$axios.post('/user/add_specification', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then(res => {
                        if (res.data.status == 'success') {
                            this.clean_item();
                            this.showMessage(res.data.message, 'text-success');
                        } else if (res.data.status == 'bad_data') {
                            this.showMessage(res.data.message, 'text-danger');
                        }
                        this.$store.commit('prop/loading_spinner_hide');
                    })
                    .catch(error => {
                        this.showMessage('Слишком большой размер файлов или проблемы с интернетом', 'text-danger');
                        console.log(error);
                        this.$store.commit('prop/loading_spinner_hide');
                    });
            },
            showMessage(text, classText) {
                this.message = {
                    text: text,
                    classText: classText,
                    show: true
                }
            },
            clean_item() {
                this.item = {
                    gen_proekt: null,
                    name: null,
                    address: null,
                    zakazchik_1: null,
                    zakazchik_2: null,
                    zakazchik_3: null,
                    gen_podr_1: null,
                    gen_podr_2: null,
                    gen_podr_3: null,
                    podr_1: null,
                    podr_2: null,
                    podr_3: null,
                    sub_podr_1: null,
                    sub_podr_2: null,
                    sub_podr_3: null,
                    postavshik: null,
                };
                this.add_files = [];
            }
        }
    }
</script>

<style scoped>

</style>