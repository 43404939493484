<template>
    <div class="m-3">
        <h3>Выгрузка спецификаций</h3>
        <b-form @submit.prevent="downloadSpecifications" class="m-2">
            <div class="row">
                <div class="col-2"></div>
                <div class="col-4">
                    <b-form-group label="Дата 'С'"
                                  label-for="form-title-input">
                        <b-form-datepicker v-model="start_dt" locale="ru" required
                                           start-weekday="1"></b-form-datepicker>
                    </b-form-group>
                </div>
                <div class="col-4">
                    <b-form-group label="Дата 'По'"
                                  label-for="form-title-input">
                        <b-form-datepicker v-model="end_dt" locale="ru" required
                                           start-weekday="1"></b-form-datepicker>
                    </b-form-group>
                </div>
                <div class="col-2"></div>
            </div>
            <div class="row">
                <div class="col-2"></div>
                <div class="col-8">
                    <transition enter-active-class="animated flipInX"
                                leave-active-class="animated flipOutX">
                        <div v-show="message.show" class="mb-2">
                            <span :class="message.classText">
                            {{message.text}}
                            </span>
                        </div>
                    </transition>
                    <div class="modal-footer m-2">
                        <b-button type="submit" variant="primary">Скачать спецификации</b-button>
                    </div>
                </div>
                <div class="col-2"></div>
            </div>
        </b-form>
        <hr style="border-width: 3px;">
        <h3>Выгрузка пользователей</h3>
        <div class="row">
            <div class="col-2"></div>
            <div class="col-8">
                <div class="modal-footer m-2">
                    <b-button @click="downloadUsers" variant="primary">Скачать пользователей</b-button>
                </div>
            </div>
            <div class="col-2"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ADownloadData",
        data() {
            return {
                message: {
                    text: null,
                    classText: null,
                    show: true
                },
                start_dt: null,
                end_dt: null,
            }
        },
        methods: {
            downloadSpecifications() {
                this.message.show = false;
                if (this.start_dt == null || this.end_dt == null) {
                    this.showMessage('Заполните обе даты', 'text-danger');
                    return null
                }

                if (this.$moment(this.start_dt).isAfter(this.$moment(this.end_dt))) {
                    this.showMessage('Первая дата должна быть раньше второй', 'text-danger');
                    return null
                }

                this.$store.commit('prop/loading_spinner_show');
                this.$axios.get('/admin/guide/download_data/specification' + '?' + Date.now(),
                    {
                        responseType: 'blob',
                        params: {
                            start_dt: this.start_dt,
                            end_dt: this.end_dt
                        }
                    })
                    .then((response) => {
                        let str_start_dt = this.$moment(this.start_dt).format('DD-MM-YYYY');
                        let str_end_dt = this.$moment(this.end_dt).format('DD-MM-YYYY');
                        let file_name = 'Спецификации_' + str_start_dt + '_' + str_end_dt + '.xlsx';
                        this.$fileDownload(response.data, file_name);
                        this.$store.commit('prop/loading_spinner_hide');
                    });
            },
            downloadUsers(){
                this.$store.commit('prop/loading_spinner_show');
                this.$axios.get('/admin/guide/download_data/users' + '?' + Date.now(),
                    {
                        responseType: 'blob',
                        params: {
                        }
                    })
                    .then((response) => {
                        this.$fileDownload(response.data, 'Пользователи.xlsx');
                        this.$store.commit('prop/loading_spinner_hide');
                    });
            },
            showMessage(text, classText) {
                this.message = {
                    text: text,
                    classText: classText,
                    show: true
                }
            },
        }
    }
</script>

<style scoped>

</style>