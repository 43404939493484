import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

const module_auth = {
    namespaced: true,
    state: {
        role: localStorage.getItem('role') || 'guest',
        login: localStorage.getItem('login') || '',
        token: localStorage.getItem('token') || '',
        fio: localStorage.getItem('fio') || '',
        balance: localStorage.getItem('balance') || '',
        city: localStorage.getItem('city') || '',
        official_position: localStorage.getItem('official_position') || '',
    },
    mutations: {
        auth_success(state, payload) {
            state.role = payload.role;
            localStorage.setItem('role', payload.role);
            state.token = payload.token;
            localStorage.setItem('token', payload.token);
            state.login = payload.login;
            localStorage.setItem('login', payload.login);
            state.fio = payload.fio;
            localStorage.setItem('fio', payload.fio);
            state.balance = payload.balance;
            localStorage.setItem('balance', payload.balance);
            state.city = payload.city;
            localStorage.setItem('city', payload.city);
            state.official_position = payload.official_position;
            localStorage.setItem('official_position', payload.official_position);
        },
        logout(state) {
            state.role = 'guest';
            localStorage.setItem('role', 'guest');
            state.token = '';
            localStorage.removeItem('token');
            state.login = '';
            localStorage.removeItem('login');
            state.fio = '';
            localStorage.removeItem('fio');
            state.balance = '';
            localStorage.removeItem('balance');
            state.city = '';
            localStorage.removeItem('city');
            state.official_position = '';
            localStorage.removeItem('official_position');
        },
        new_balance(state, payload){
            state.balance = payload.new_balance;
        }
    },
    actions: {},
    getters: {}
};

const module_property = {
    namespaced: true,
    state: {
        loading_spinner: false,
    },
    mutations: {
        loading_spinner_show(state) {
            state.loading_spinner = true;
        },
        loading_spinner_hide(state) {
            state.loading_spinner = false;
        },
    },
    actions: {},
    getters: {}
};

const store = new Vuex.Store({
        modules: {
            auth: module_auth,
            prop: module_property
        }

    }
);

export default store