<template>
    <div class="m-2">
        <b-navbar class="text-dark font-weight-light" toggleable="lg" type="light" variant="warning">
            <template class="row">
                <div class="col-3">
                    <a href="https://www.k-flex.ru/" target="_blank">
                        <img src="./../assets/logo.png" alt="" class="img-fluid">
                    </a>
                </div>
                <template v-if="role === 'user'">
                    <div class="col-3">
                        <span class="font-weight-bold">{{fio}}</span><br>
                        <span class="font-weight-bold" style="font-size:16px;">{{official_position}}</span><br>
                    </div>
                    <div class="col-3">
                        <span class="font-weight-bold" style="font-size:16px;">{{city}}</span><br>
                        <span class="font-weight-bold" style="font-size:16px;">Баллы: {{balance}}</span><br>
                    </div>
                </template>
                <template v-else-if="role === 'admin' || role === 'manager'">
                    <div class="col-3">
                        <span class="font-weight-bold">{{fio}}</span><br>
                        <span class="font-weight-bold" style="font-size:16px;">{{official_position}}</span><br>
                    </div>
                    <div class="col-3">
                    </div>
                </template>
                <template v-else>
                    <div class="col-6">
                    </div>
                </template>
                <template v-if="role == null || role === '' || role === 'guest'">
                    <div class="col-3">

                    </div>
                </template>
                <template v-else>
                    <div class="col-3">
                        <span class="font-weight-bold" @click="logout" style="cursor: pointer;">Выход</span>
                    </div>
                </template>
            </template>
        </b-navbar>

        <template v-if="role === 'user'">
            <b-nav tabs fill class="m-1" toggle-class="text-dark">
                <b-nav-item class="m-1" to="/user/prizes" exact exact-active-class="active">
                    Главная
                </b-nav-item>
                <b-nav-item class="m-1" to="/user/news" exact exact-active-class="active">Новости</b-nav-item>
                <b-nav-item class="m-1" to="/user/balance" exact exact-active-class="active">Мои баллы</b-nav-item>
                <b-nav-item class="m-1" to="/user/add_specification" exact exact-active-class="active">
                    Отправить заявку
                </b-nav-item>
                <b-nav-item class="m-1" to="/user/specifications/1" exact exact-active-class="active">
                    История заявок
                </b-nav-item>
            </b-nav>
        </template>
        <template v-else-if="role === 'manager'">
            <b-nav tabs fill class="m-1" toggle-class="text-dark">
                <b-nav-item class="m-1" to="/manager/specifications/1" exact exact-active-class="active">
                    Спецификации
                </b-nav-item>
                <b-nav-item class="m-1" to="/manager/users" exact exact-active-class="active">
                    Пользователи
                </b-nav-item>
            </b-nav>
        </template>
        <template v-else-if="role === 'admin'">
            <b-nav tabs fill class="m-1" toggle-class="text-dark">
                <b-nav-item class="m-1" to="/admin/corrections/1" exact exact-active-class="active">
                    Коррекции
                </b-nav-item>
                <b-nav-item class="m-1" to="/admin/specifications/1" exact exact-active-class="active">
                    Спецификации
                </b-nav-item>
                <b-nav-item class="m-1" to="/admin/prize_applications/1" exact exact-active-class="active">
                    Заявки на призы
                </b-nav-item>
                <b-nav-item class="m-1" to="/admin/users/1" exact exact-active-class="active">
                    Пользователи
                </b-nav-item>
                <b-nav-item-dropdown text="Справочник">
                    <b-dropdown-item to="/admin/guide/regions">Регионы</b-dropdown-item>
                    <b-dropdown-item to="/admin/guide/prizes">Призы</b-dropdown-item>
                    <b-dropdown-item to="/admin/guide/news/1">Новости</b-dropdown-item>
                    <b-dropdown-item to="/admin/guide/companies/1">Компании</b-dropdown-item>
                    <b-dropdown-item to="/admin/guide/official_positions/1">Должности</b-dropdown-item>
                    <b-dropdown-item to="/admin/guide/download_data">Выгрузка</b-dropdown-item>
                </b-nav-item-dropdown>
            </b-nav>
        </template>
        <template v-else>
            <b-nav tabs fill class="m-1" toggle-class="text-dark">
                <b-nav-item disabled class="m-1">Авторизуйтесь в сервисе</b-nav-item>
            </b-nav>
        </template>

    </div>
</template>

<script>
    export default {
        name: "Header",
        data() {
            return {}
        },
        methods: {
            logout() {
                this.$store.commit('auth/logout');
                this.$router.push('/');
            }
        },
        computed: {
            role() {
                return this.$store.state.auth.role;
            },
            login() {
                return this.$store.state.auth.login;
            },
            fio() {
                return this.$store.state.auth.fio;
            },
            balance() {
                return this.$store.state.auth.balance;
            },
            city() {
                return this.$store.state.auth.city;
            },
            official_position() {
                return this.$store.state.auth.official_position;
            }
        }
    }
</script>

<style scoped>
    .nav-item a {
        color: black !important
    }

    .nav-item {
        background-color: #f8e681 !important
    }
</style>