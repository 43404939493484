import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue2-animate/dist/vue2-animate.min.css'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './axios-options'
import moment from 'moment'

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.prototype.$axios = axios;
Vue.prototype.$fileDownload = require('js-file-download');

moment.locale('ru');
Vue.prototype.$moment = moment;


new Vue({
    store,
    router,
    render: h => h(App),
}).$mount('#app');


