<template>
    <div>
        <h5>403 Запрещено</h5>
    </div>
</template>

<script>
    export default {
        name: "Page_403"
    }
</script>

<style scoped>

</style>