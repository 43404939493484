<template>
    <div class="m-3">
        <h3>Пользователи</h3>

        <b-table striped :items="items" :fields="fields" primary-key="id"
                 head-variant="dark"
                 table-variant="light"
                 :tbody-transition-props="{name:'flip-list'}"
                 :tbody-tr-class="rowClass"
        >
            <template #cell(birthday)="data">
                {{ $moment(data.value).format('ll')}}
            </template>
            <template #cell(company_id)="data">
                {{ company_by_id[data.value]['name']}}
            </template>
            <template #cell(official_position_id)="data">
                {{ op_by_id[data.value]['name']}}
            </template>
            <template #cell(city_id)="data">
                {{ city_by_id[data.value]['name']}}
            </template>
            <template #cell(is_male)="data">
                {{ data.value ? 'Муж': 'Жен'}}
            </template>
        </b-table>
    </div>
</template>

<script>
    export default {
        name: "MUsers",
        data() {
            return {
                items: [],
                fields: [
                    {
                        label: "Фио",
                        key: 'fio',
                        sortable: true
                    },
                    {
                        label: "Email",
                        key: 'mail',
                        sortable: true
                    },
                    {
                        label: "Телефон",
                        key: 'phone',
                        sortable: true
                    },{
                        label: "Баланс",
                        key: 'balance',
                        sortable: true
                    },{
                        label: "ДР",
                        key: 'birthday',
                        sortable: true
                    },{
                        label: "Компания",
                        key: 'company_id',
                        sortable: true
                    },{
                        label: "Позиция",
                        key: 'official_position_id',
                        sortable: true
                    },{
                        label: "Город",
                        key: 'city_id',
                        sortable: true
                    },{
                        label: "Пол",
                        key: 'is_male',
                        sortable: true
                    },
                ],
                city_by_id: {},
                company_by_id: {},
                op_by_id: {},
                item_modal: {
                    id: null,
                    mail: null,
                    fio: null,
                    phone: null,
                    balance: null,
                    birthday: null,
                    company_id: null,
                    official_position_id: null,
                    city_id: null,
                    is_male: null,
                },
            }
        },
        methods: {
            loadData() {
                this.$store.commit('prop/loading_spinner_show');
                this.page = this.$route.params.page;
                this.$axios.post('/manager/users_data_for_page')
                    .then((res) => {
                        this.items = res.data.items;
                        this.city_by_id = res.data.city_by_id;
                        this.company_by_id = res.data.company_by_id;
                        this.op_by_id = res.data.op_by_id;
                        this.$store.commit('prop/loading_spinner_hide');
                    })
                    .catch((error) => {
                        // eslint-disable-next-line
                        console.log(error);
                        this.$store.commit('prop/loading_spinner_hide');
                    });
            },
            showEditModal(item) {
                this.item_modal = JSON.parse(JSON.stringify(item));
                this.$refs['modalItem'].show();
            },
            hideEditModal() {
                this.$refs['modalItem'].hide();
            },
        },
        created() {
            this.loadData();
        }
    }
</script>

<style scoped>

</style>